'use strict';

var core = require('integrations/cart');
var cart = require('./cart/cart');

core.baseFiles.cart = function () {
    return cart.init.call(cart);
};

module.exports = core;
