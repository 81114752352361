'use strict';
var productBase = require('../product/base');
var helper = require('./customizerHelper');

var customizer = {
	initCustomizer: function() {
		var apiKey = $('[data-customizer-apikey]').data('customizer-apikey');
		var getVariantsEndpoint = $('[data-customizer-get-variants-endpoint]').data('customizer-get-variants-endpoint');
		var getArtworkUrl = $('[data-customizer-get-artwork]').data('customizer-get-artwork');
		var productId = $('[data-customizer-product-id]').data('customizer-product-id');
		var addCustomToCartUrl = $('[data-customizer-add-to-cart]').data('customizer-add-to-cart');
		var formatPriceUrl = $('[data-customizer-format-price]').data('customizer-format-price');
		var productDataUrl = $('[data-customizer-product-data]').data('customizer-product-data');
        var isMaster = $('[data-customizer-is-master]').data('customizer-is-master');
		var showProgram = $('[data-customizer-show-program]').data('customizer-show-program');

        if(isMaster == true || isMaster == 'true') {
            $('.product-detail').data('pid', '');
        }

		if (apiKey) {
			customizer.checkCustomizableStatusAndUpdateButtons();

			$('.product-customize-button').click(function() {
				helper.customizerSpinner.start();

				// Get yotpo stars
				var startHtml = $('.product-rating-stars').first().html();
				$('.customizer-stars-placeholder').html(startHtml);

                $('#bc-chat-container, .bcFloat').addClass('d-none');
				helper.handleRaceCondition();

				$('#customizerModal').modal('show');
				$('#customize__placeholder').append('<div></div>');

				// eslint-disable-next-line no-undef
				CustomizerWidget.default.createWidget({
					apiKey: apiKey,
					products: productId,
					allowPrograms: showProgram,
					container: $('#customize__placeholder div')[0],
					getVariantsEndpoint: getVariantsEndpoint,
					formatPriceEndpoint: formatPriceUrl,
					productDataEndpoint: productDataUrl,
					artworkEndpoint: getArtworkUrl,
					allowProgram: showProgram,
					selectedLot: helper.getSelectedLotValue(),
					selectedVariant: helper.getSelectedVariantValue(),

					onSizingChart: function() {
                        // if the sizechart is from customizer append after all the modal-backdrops
                        var productId = $('.product-detail').find('.product-id').html();
                        var $sizeChartModal = $('.size-chart-modal[data-product="' + productId + '"]');
                        var $sizeChartContainer = $('.product-detail').find('.size-chart .sizechart-link').parent(); // brute force it to select PDP size chart
                        $sizeChartModal.appendTo('body');
                        $sizeChartModal.one('hide.bs.modal', event => {
                            $sizeChartModal.appendTo($sizeChartContainer);
                        });
                        // Base AB adds a max-height to the quickview modal, which is also applied to the size chart modal
                        // Reset the max-height when launching the size chart modal
                        $sizeChartModal.find('.modal-body').css('max-height', '');

                        // Autobahn sets the modal title when the quickview is launched, and because the size chart
                        // is contained in the quickview modal the title is also applied to the size chart.
                        // Remove the title from the size chart modal
                        $sizeChartModal.find('.modal-title').remove();

                        $('#sizeChartModal').modal('show');
					},
					onBusinessOrder: function() {
						$('#quantityFormModal').modal('show');
					},
					onCancel: function() {
						$('#customizerModal').modal('hide');
                        $('#bc-chat-container, .bcFloat').removeClass('d-none');
					},
					onBusy: function(isBusy) {
						if (isBusy) {
							helper.customizerSpinner.start();
						} else {
							helper.customizerSpinner.stop();
						}
					},
					onDone: function(result) {
						if (result.mode === 'program') {
							$(document).trigger('customizer:clearSelectedPrograms');
							$(document).trigger('customizer:programAdd',  { result: result });
						} else {
							customizer.addCustomProductToCart(result.recipe, addCustomToCartUrl);
							$("#customizerModal").one("hidden.bs.modal", function () {
								// put your default event here
								$('#bc-chat-container, .bcFloat').removeClass('d-none');
							});
						}
					}
				});
			});

			// Open the customizer if we get a recipe passed in
			var recipe = $('[data-customizer-recipe]').data('customizer-recipe');
			if (recipe || window.location.search.includes('recipe=')) {
				$('.product-customize-button').trigger('click');
			}

            $('body').on('product:afterAttributeSelect', function(e, response) {
                if(response.data.product.custom.isCustomizable) {
                    $('.product-customize-button').removeClass('d-none');
                    $('.product-customize-button').prop('disabled', false);
                }

                var product = response.data.product;
                var currentProductId = helper.getSelectedVariantValue();
                if(product.productType === 'master' && product.id == currentProductId) {
                    $('.product-detail').data('pid', '');
                }

            });
		}
	},
	checkCustomizableStatusAndUpdateButtons: ($target) => {
		var $button = typeof ($target) !== 'undefined' ? $target : $('[data-customizer-product-id]');
		var productId = $button .data('customizer-product-id');
		var url = $button.data('customizer-is-customizable');
		var $customizeButtonContainer = $('.customizable-product-actions, .visible-if-customizable');
		var $notCustomizeButtonContainer = $('.not-custimzable-product-actions');
		return $.ajax({
			type: 'POST',
			url: url,
			data: {
				pid: productId
			},
			success: function(response) {
				if (response && response.isCustomizableResponse) {
					$customizeButtonContainer.removeClass('d-none').addClass('d-block');
					$notCustomizeButtonContainer.removeClass('d-block').addClass('d-none');
				} else {
					$customizeButtonContainer.removeClass('d-block').addClass('d-none');
					$notCustomizeButtonContainer.removeClass('d-none').addClass('d-block');
				}
			},
			error: function(error) {
				$customizeButtonContainer.removeClass('d-block').addClass('d-none');
				$notCustomizeButtonContainer.removeClass('d-none').addClass('d-block');
				window.console.error(error);
			}
		});
	},
	addCustomProductToCart: (recipe, url) => {
		helper.customizerSpinner.start();
	
		$.ajax({
			type: 'POST',
			url: url,
			data: recipe,
			success: function(response) {
				helper.customizerSpinner.stop();

				if (response.error === true) {
					if (response.message === 'NOT_CUSTOMIZABLE') {
						$('#customizerModal .cz2__modal-popup--done .cz2__modal-popup__button--ok').trigger('click');
						$('#customizerErrorModal').modal('show');
						return;
					}
				}

				// Handle bonus products
				if (response.bonusProductData.constructor === Object && Object.keys(response.bonusProductData).length) {
					productBase.methods.chooseBonusProducts(response.bonusProductData);
				}
	
				if (response.cart) {
					$('.minicart .minicart-quantity').text(response.cart.numItems).attr('data-quantity', response.cart.numItems).removeClass('qty-0').addClass('qty-' + response.cart.numItems);
				}

				window.collect.trackCart();
			},
			error: function(error) {
				console.error('Add To Cart Error: ', error);
				helper.customizerSpinner.stop();
			}
		});
	}

};

module.exports = customizer;
