require('bootstrap-select');

module.exports = {
    updateValidity: function () {
        $('.selectpicker').on('changed.bs.select', function () {
            if (this.checkValidity()) {
                $(this).removeClass('is-invalid');
            }
        });
    }
};
