'use strict';

function collectUtils() {
    const mcEnabled = $('#collectData').data('mc-enabled');
    if (mcEnabled) {
        // get default data
        var data = $('#collectData').data();

        // set these each time the page loads
        this.pageload = function() {
            var payload = {};
            _etmc.push(['setOrgId', data.mid]);

            // on PDP
            if ($('.page[data-action*=Product-Show]').length) {
                // get master product id
                var productId = $('.style-number');
                if (productId.length) {    
                    payload = {
                        "item" : $(productId[0]).text()
                    };
                }
            }

            // on order confirm page
            if ($('.page[data-action=Order-Confirm]').length) {
                var orderId = $('#ordernumber').val(); // $('[data-order-id]').data('order-id');
                // make ajax request to get order details
                if (data.orderUrl) {
                    $.ajax({
                        url: data.orderUrl,
                        method: 'GET',
                        dataType: 'json',
                        data: {
                            id: orderId
                        },
                        success: function(data) {
                            if (data.items && data.items.length > 0) {
                                _etmc.push(["trackConversion", {
                                    "cart": data.items,
                                    "order_number" : orderId,
                                    "discount" : data.discount,
                                    "shipping" : data.shipping,
                                    "details" : { "total" : data.total }
                                }]);
                            }
                        },
                        error: function(err) {
                            console.error(err);
                        }
                    });
                }
            }

            // on category / search / plp
            if ($('.page[data-action=Search-Show]').length) {
                var isCategoryPage = $('[data-category-search]').data('category-search') || false;
                var categoryId = $('[data-category-id]').data('category-id') || '';
                var searchKeyword = $('[data-search-keyword]').data('search-keyword') || '';

                if (isCategoryPage) {
                    payload = {
                        "category" : categoryId
                    };
                } else {
                    payload = {
                        "search" : searchKeyword
                    };
                }
            }

            _etmc.push(['trackPageView', payload]);
        };

        // set user info whenever a user get's a contact id
        // either authentication or email sign up
        // it appears the main reason for the localStorage usage is so the emailSignup functionality in the footer
        // isn't used to create multiple emails
        this.setUserInfo = function(updateContactId) {
            if (updateContactId) {
                data.contactId = updateContactId;
            }

            if (!data.contactId) {
                data.contactId = window.localStorage.getItem('vfwContactId');
            }

            if (data.contactId) {
                _etmc.push(['setUserInfo', {'email': data.contactId}]);
                _etmc.push(['trackPageView']);
            }
        };

        // send cart data anytime the cart changes
        this.trackCart = function() {
            if (data.cartItemsUrl) {
                $.ajax({
                    url: data.cartItemsUrl,
                    method: 'GET',
                    dataType: 'json',
                    success: function(data) {
                        if (data.items && data.items.length > 0) {
                            _etmc.push(["trackCart", {
                                "cart": data.items
                            }]);
                        } else if (data.items && data.items.length === 0) {
                            _etmc.push(["trackCart", {
                                "clear_cart": true
                            }]);
                        }
                    },
                    error: function(err) {
                        console.error(err);
                    }
                });
            }
        };
    }
};

module.exports = {
    collectUtils: collectUtils
};
