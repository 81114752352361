'use strict';




module.exports = {
	industryPicker: function() {
		$('#mailingListSignupForm').on('change changed.bs.select', 'select[name="dwfrm_emailSignup_industry"]', function() {
			if ($(this).val() === 'Other') {
				$('input[name="dwfrm_emailSignup_industryOther"]').show();
			} else {
				$('input[name="dwfrm_emailSignup_industryOther"]').hide();
			}
		});
	},
	newsletterSubmitHandler: function() {

		function displayMessage(data, button) {
			$.spinner().stop();
			var status;
			if (data.success) {
				status = 'alert-success';
			} else {
				status = 'alert-danger';
			}
		
			if ($('.email-signup-message').length === 0) {
				$('body').append(
				   '<div class="email-signup-message"></div>'
				);
			}
			$('.email-signup-message')
				.append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');
		
			setTimeout(function () {
				$('.email-signup-message').remove();
				button.removeAttr('disabled');
			}, 3000);
		}
		
		$('body').on('submit', '#mailingListSignupForm, #mailingListSignupFormPop', function(event) {

			// Bot protection
			// Check common bot user-agent string. Bot detection is not perfect, but it's better than nothing.
			const botRegex = /bot|crawl|spider|archiver|curl|facebook|google|bing|yahoo|yandex|twitter|botlink|msn|teoma|slurp|wget|httrack|python|ruby|java|libwww|httpclient|mediapartners|lighthouse|facebookexternalhit|embedly|quora|outbrain|pinterest|slack|vkShare|W3C_Validator/i;
			if (botRegex.test(navigator.userAgent)) {
				return false;
			}

			var form = $(this);
			event.preventDefault();
			var delimiter = form.attr('action').indexOf('?') > -1 ? '&' : '?';
			var url = form.attr('action') + delimiter + form.serialize();
			form.spinner().start();
			form.trigger('emailsignup:submit', event);
			$.ajax({
				url: url,
				type: 'post',
				dataType: 'json',
				data: form.serialize(),
				success: function(data) {
					form.spinner().stop();
					$( '#signupModalWrapper' ).load(data.formTemplate, function() {
						if (form[0].id === 'mailingListSignupFormPop') {
							$('#mailingListDetailsModal .modal-body').addClass('d-none');
							$('#mailingListDetailsModal .modal-body.signup-details-form').removeClass('d-none');
							$('#mailingListDetailsModal .modal-title').addClass('d-none');
							$('#mailingListDetailsModal .modal-title.signup-details').removeClass('d-none');
						} else {
							$('#mailingListDetailsModal').modal();
						}
					});
					if (data.success) {
						form.find('select, input').each(function () {
							if ($(this).hasClass('selectpicker')) {
								$(this).selectpicker('val', '');
							} else {
								$(this).val('');
							}
						});
						form.find('.is-invalid, .is-validation-error, .is-validation-ok')
							.removeClass('is-invalid is-validation-error is-validation-ok');
					}
				},
				error: function() {
					form.spinner().stop();
				}
			});
		});

		var $emailSignupComponent = $('form.component_email_signup');

		$emailSignupComponent.on('submit', function (e, urlOverride) {
			e.preventDefault();

			var $btn = $(this).find('.signup-email-component');
			var xhr = $emailSignupComponent.data('xhr');

			$emailSignupComponent.data('xhr', $.ajax({
				url: urlOverride || $emailSignupComponent.attr('action'),
				type: $emailSignupComponent.attr('method') || 'post',
				data: $emailSignupComponent.find(':input:not(:disabled)').serialize(),
				dataType: 'json',
				beforeSend: function () {
					// Drop duplicate requests
					xhr && xhr.abort && xhr.abort();

					$.spinner().start();

					$btn.attr('disabled', true);
				},
				success: function (data) {
					displayMessage(data, $btn);
				},
				error: function ($xhr, err, other) {
					var msg = $xhr.responseJSON && $xhr.responseJSON.message;
					displayMessage({success:false, msg: msg}, $btn);
				},
				complete: function () {
					$.spinner().stop();
					$btn.removeAttr('disabled');
					$emailSignupComponent[0].reset();
				}
			}));
		})

		// For SFRA compatibility
		$emailSignupComponent.find('.signup-email-component').on('click', function (e) {
			e.preventDefault();
			$emailSignupComponent.trigger('submit', $(this).data('href'));
		});
	},
	detailsSubmitHandler: function() {
		$('#mailingDetailsForm').submit(function(event) {
			var form = $(this);
			event.preventDefault();
			var delimiter = form.attr('action').indexOf('?') > -1 ? '&' : '?';
			var url = form.attr('action') + delimiter + form.serialize();
			form.spinner().start();
			form.trigger('emailsignupdetails:submit', event);
			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				data: form.serialize(),
				success: function() {
					form.spinner().stop();
					$('.signup-details-form').addClass('d-none');
					$('.signup-details-received').removeClass('d-none');
					$('#mailingListDetailsModal').modal('hide');
				},
				error: function() {
					form.spinner().stop();
				}
			});
		});
	},
	bulwarkBrandMultipicker: function() {
		$('#brandPicker').on('change', function() {
			var selectedOptions = [];
			if ($(this)[0].selectedOptions) {
				var options = Array.from($(this)[0].selectedOptions);
				for (var index in options) {
					if (options[index]) {
						var option = options[index];
						selectedOptions.push(option.value);
					}
				}
			}
			$('input[name="dwfrm_emailSignup_bulwarkBrands"]').val(selectedOptions.join(';'));
		});
	}
};
