'use strict';

var customizerHelper = {
    getSelectedVariantValue: () => {
		return $('.product-detail').data('pid');
	},
	getSelectedLotValue: () => {
		return $('.product-detail-attributes .swatch-colors.selected').data('lot');
	},
    customizerSpinner: {
		start: function() {
			$('.customizer-spinner').fadeIn();
		},
		stop: function() {
			$('.customizer-spinner').fadeOut();
		}
	},
    handleRaceCondition: () => {
		if ($('#bc-chat-container').length > 0 || $('.bcFloat').length > 0) {
			// no race condition to handle continue on our merry way
		} else {
			// create our loading function
			var checkIfBoldChatHasLoaded = function() {
				if (($('#bc-chat-container').length > 0 && $('#bc-chat-container').is(':visible')) || $('.bcFloat').length > 0 && $('.bcFloat').is(':visible')) {
					// at least one of them has loaded and is visible
					$('#bc-chat-container, .bcFloat').addClass('d-none');
					window.clearInterval(boldChatInterval);
				}
			}
			// neither are on the page, meaning we most likely have the race condition.
			var boldChatInterval = window.setInterval(checkIfBoldChatHasLoaded, 2000);
		}
	}
};

module.exports = customizerHelper;