'use strict';

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
var consent = {
    getString: (urlAccept) => {
        //immediately return a calculate string value
        return `<div class="consent-drawer" id="consent-tracking" role="dialog" data-url-accept="${urlAccept}"></div>`;
    },
    showConsentDrawer: ($trackingElement) => {
        if ([undefined, null].indexOf($trackingElement) !== -1 || !$trackingElement.data('caonline')) {
            return;
        }

        var namespace = '.consenttrackingdrawer ',
            urlContent = $trackingElement.data('url'),
            urlAccept = $trackingElement.data('accept')

        var htmlString = consent.getString(urlAccept);

        $('body').append(htmlString);

        var $consentTracking = $('#consent-tracking');
        var $consentContent = $('#consent-content');
        $consentTracking.append($consentContent.children()); // Move to $consentTracking

        $consentTracking.find('.button-accept').on('click' + namespace, (event) => {
            event.preventDefault();
            var url = $(event.target).closest('[data-url-accept]').data('url-accept');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                context: $consentTracking,
                complete: function () {
                    this.remove();
                },
                error: function () {
                    this.remove();
                }
            });
        });
    },
    init: () => {
        var namespace = '.consenttracking ',
            $body = $('body'),
            $trackingConsent = $('.tracking-consent');

        if ($('.consented').length === 0 && $trackingConsent.hasClass('api-true')) {
            consent.showConsentDrawer($trackingConsent);
        }

        $trackingConsent.each((index, element) => {
            var $this = $(this);

            if ($this.hasClass('api-true')) {
                $this.on('click' + namespace, () => {
                    consent.showConsentDrawer($this);
                });
            }
        });
    }
};

module.exports = consent;
