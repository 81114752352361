'use strict';

const productBase = require('core/product/base');

function updateSelectedValueUrl(originalURL, $employeeRow) {
    // Update attribute URL to match multiple variation attributes (if needed)
    var $attrs = $employeeRow.find('.b2b-qv-attributes .b2b-qv-attribute[data-attr]');
    var $selectedAttrs = $attrs.filter(function () { 
        return $(this).val(); 
    });
    if ($selectedAttrs.length > 1) {
        var url = new URL(originalURL);
        // Get demandware attribute prefix (looks like: dwvar_PRODUCTID)
        var queryParamNames = url.searchParams.keys().toArray();
        var dwAttrQueryParam = queryParamNames.find(function (item) {
            return item.indexOf('dwvar_') === 0;
        });
        var dwQueryParamPieces = dwAttrQueryParam.split('_');
        dwQueryParamPieces.pop();
        var dwPrefix = dwQueryParamPieces.join('_');

        // Update any attribute values in the selected url, based on the attributes selected in the dropdowns
        $selectedAttrs.each(function () {
            var attrName = $(this).data('attr');
            var attrValue = $(this).find(':selected').data('attr-value');
            url.searchParams.set(dwPrefix + '_' + attrName, attrValue);
        });

        return url.toString();
    } else {
        // Url does not need updating if only one variation attribute is used
        return originalURL;
    }
}

/**
 * Replace a query parameter in a URL (can work for relative URLs)
 */
function updateURLQueryParam(uri, key, value) {
    var hashIndex = uri.indexOf('#');
    var hash = hashIndex === -1 ? ''  : uri.substr(hashIndex);
    var uri = hashIndex === -1 ? uri : uri.substr(0, hashIndex);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
}

var programQuickView = {
    handleEvents: function () {
        var $doc = $(document);

        $('body').on('quickview:afterLoad', function (e, data) {
            if (data && 'programDetailCTAs' in data) {
                $('.program-detail-ctas').replaceWith(data.programDetailCTAs);
                $('#settingsProgramModal').replaceWith(data.programSettingsModal);
            }
        });

        $doc.on('change', '.employee-list-quickview .employee', function (e) {
            e.preventDefault();
            var $employee = $(this);
            var data = $employee.data();

            programQuickView.toggleATCButton();
        });

        $doc.on('click', '.add-employee-quickview', function (e) {
            e.preventDefault();
            $('.add-employee-quickview-form').slideToggle();
        });

        $doc.on('submit', '.add-employee-form', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var data = $form.serialize();
            var $employeeList = $('.employee-list-quickview .employee-list-col');
            var $employeeListEmpty = $('.employee-list-row-empty');
            var $addEmployeeAlert = $('.quickview-addemployee-alert');
            $addEmployeeAlert.hide();
            $.ajax({
                url: url,
                data: data,
                method: 'POST',
                success: function (data) {
                    if (data.success) {
                        $employeeListEmpty.hide();
                        var $data = $(data.html);
                        // Find where new employee is listed in response and pluck new employee HTML
                        var $newEmployeeList = $data.find('.employee-list-col');
                        var $target = null;
                        var $newEmployeeRow = $newEmployeeList.find('.employee-list-row').filter(function () {
                            var $employeeRow = $(this);
                            var $oldRow = $employeeList.find(`[data-employee-id="${$employeeRow.data('employee-id')}"]`);
                            var isNewRow = $oldRow.length === 0;
                            if (!isNewRow) {
                                $target = $oldRow;
                            }
                            return isNewRow;
                        });

                        // Add new employee HTML to existing list (avoids wiping user's progress)
                        if ($newEmployeeRow.length > 0) {
                            if ($target === null) {
                                // Insert as first child element
                                $employeeList.prepend($newEmployeeRow[0]);
                            } else {
                                // Insert after another pre-existing employee row
                                $target.after($newEmployeeRow[0]);
                            }
                        }

                        $form[0].reset();
                        productBase.enableQuantitySteppers(); // New employee needs their quantity stepper event intialized
                    } else {
                        $addEmployeeAlert.text(data.message).fadeIn('fast');
                    }
                },
                error: function (err) {
                    console.error(err);
                    $addEmployeeAlert.text(data.message).fadeIn('fast');
                }
            });
        });

        $doc.on('click', '.b2b-qv-headers .toggle', function (e) {
            e.preventDefault();
            var $this = $(this);
            var target = $this.data('qv-target');
            var $b2bQvHeader = $('.b2b-qv-headers .toggle');
            var $b2bQvBody = $('.b2b-qv-body > div');
            var $b2bTabPrices = $('.single-header-price, .select-header-price');
            // add d-none to b2bQVBody
            $b2bQvBody.addClass('d-none');
            $b2bTabPrices.addClass('d-none');
            // remove active from b2bQvHeader
            $b2bQvHeader.removeClass('active');
            //remove d-none from targets
            $('.' + target).removeClass('d-none');
            // add active to current
            $this.addClass('active');
        });

        // remove on click event from .b2b-qv-atc and then re-add it
        $doc.on('click', '.b2b-qv-atc', function (e) {
            e.preventDefault();
            var $this = $(this);
            // get data-b2b-qv-atc-url url
            var url = $this.data('b2b-qv-atc-url');

            var $b2bQvContainer = $('.b2b-qv-container');

            var $qvAddtocartAlert = $('.quickview-addtocart-alert');
            $qvAddtocartAlert.hide();

            var products = programQuickView.getProducts();
            // get the number of keys in products
            if (products.length > 0) {
                $b2bQvContainer.spinner().start();
                 $.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        products: JSON.stringify(products)
                    },
                    success: function(response) {
                        if (response.success) {
                            // do something
                            $('body').trigger('product:afterAddToCart', response);
                            $('.minicart').trigger('count:update', response);
                        } else {
                            $qvAddtocartAlert.text(response.message).show('fast');
                        }
                        $b2bQvContainer.spinner().stop();
                    },
                    error: function(error, ajaxOptions, thrownError) {
                        // Don't stop spinner or print error if ajax call purposely aborted
                        if (thrownError === 'abort') {
                            return;
                        }
                        console.error(error);
                        $b2bQvContainer.spinner().stop();
                    }
                });
            }
        });

        $doc.on('change', '.b2b-qv-select-body .b2b-qv-quantity', function (e) {
            e.preventDefault();
            var $employeeRow = $(this).closest('.employee-list-row');
            var $selectedOption = $(this).find('option:selected');

            var $b2bQvAttributes = $employeeRow.find('.b2b-qv-attributes select');
            var allSelected = true;
            $b2bQvAttributes.each(function() {
                var $this = $(this);
                if ($this.val() === '' || $this.find(':selected').is(':disabled')) {
                    allSelected = false;
                }
            });

            programQuickView.updateDropdownQuantities($employeeRow);

            // If attribute selection is complete
            if (allSelected) {
                // Update PID in quantity URL to match employee sized product
                var curPID = $employeeRow.find('.employeeProduct').val();
                var selectedValueURL = updateURLQueryParam($selectedOption.attr('data-url'), 'pid', curPID);
                programQuickView.attributeSelect(selectedValueURL, $employeeRow);
                programQuickView.toggleATCButton();
            }
        });

        $doc.on('change', '.b2b-qv-select-body .b2b-qv-attribute', function (e) {
            e.preventDefault();
            var $employeeRow = $(this).closest('.employee-list-row');
            var selectedValueURL = updateSelectedValueUrl(e.currentTarget.value, $employeeRow);
            programQuickView.attributeSelect(selectedValueURL, $employeeRow);
            programQuickView.toggleATCButton();
        });
    },
    updateDropdownQuantities: function ($employeeRowContainer) {
        var curQuantity = $employeeRowContainer.find('.b2b-qv-quantity').val();
        var $options = $employeeRowContainer.find('.b2b-qv-attributes select option');
        $options.each(function() {
            var $option = $(this);
            if ($option.val() !== '') {
                var newURL = updateURLQueryParam($option.val(), 'quantity', curQuantity);
                $option.val(newURL);
            }
        });
    },
    toggleATCButton: function() {
        var products = programQuickView.getProducts();
        if (products.length > 0) {
            $('.b2b-qv-atc').removeAttr('disabled');
        } else {
            $('.b2b-qv-atc').attr('disabled', 'disabled');
        }
    },
    getProducts: function() {
        // interate through each .employee-list-row and check if the .employeeProduct is ready and the .employee checkbox is checked. if so then add to cart
        var $employeeList = $('.employee-list-row');
        if (!$employeeList.length) {
            return;
        }
        var products = [];
        $employeeList.each(function() {
            var $employee = $(this);
            var $employeeProduct = $employee.find('.employeeProduct');
            var $employeeCheckbox = $employee.find('.employee');
            var $qty = $employee.find('.b2b-qv-quantity');

            if ($employeeCheckbox.is(':checked')) {
                // is every product attributes selected and the checkbox checked
                var $b2bQvAttributes = $employee.find('.b2b-qv-attributes select');
                var allSelected = true;
                $b2bQvAttributes.each(function() {
                    var $this = $(this);
                    if ($this.val() === '') {
                        allSelected = false;
                    }
                });

                if (!allSelected) {
                    products = [];
                    return false;
                }

                if ($employeeProduct.attr('data-isready') === 'true' && $employeeProduct.attr('data-incomplete') !== true && allSelected) {
                    var employeeID = $employeeCheckbox.val();
                    var itemUUID = $employeeProduct.attr('data-itemuuid');
                    var qty = $qty.length ? $qty.val() : 1;
                    var pid = $employeeProduct.val();
                    products.push({
                        id: pid,
                        employeeID: employeeID,
                        itemUUID: itemUUID,
                        matched: true,
                        quantity: qty
                    });
                } 
            }
        });
        return products;
    },
    attributeSelect: function(selectedValueUrl, $employeeRowContainer) {
        if(!selectedValueUrl) {
            return;
        }

        if (selectedValueUrl) {
            $('body').trigger('product:beforeB2BAttributeSelect', {
                url: selectedValueUrl,
                container: $employeeRowContainer
            });

            // append the programid and employee id to the url
            var programID = $employeeRowContainer.attr('data-program-id');
            var employeeID = $employeeRowContainer.attr('data-employee-id');
            selectedValueUrl += '&programid=' + programID + '&employeeid=' + employeeID;

            $.ajax({
                url: selectedValueUrl,
                method: 'GET',
                success: function (data) {
                    programQuickView.handleVariantResponse(data, $employeeRowContainer);
                    programQuickView.updateQuantities(data.product.quantities, $employeeRowContainer);

                    $('body').trigger('product:afterB2BAttributeSelect', {
                        data: data,
                        container: $employeeRowContainer
                    });
                },
                error: function (err) {
                    console.error(err);
                }
            });
        }
    },
    updateQuantities: function(quantities, $employeeRowContainer) {
        if(!quantities) {
            return;
        }
        var $b2bQvQuantity = $employeeRowContainer.find('.b2b-qv-quantity');
        if ($b2bQvQuantity.length) {
            var optionsHtml = quantities.map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                    selected + '>' + quantity.value + '</option>';
            }).join('');
            $b2bQvQuantity.html(optionsHtml);
        }
    },
    handleVariantResponse: function(response, $employeeRowContainer) {
        if (!response) {
            return;
        }
        // Update pricing *
        // finde .employeeProduct in $employeeRowContainer and replace with response.product.id
        if ('product' in response){
            var $employeeProduct = $employeeRowContainer.find('.employeeProduct');
            $employeeProduct.val(response.product.id).attr('data-isready', response.product.readyToOrder);

            // update status & availability
            var $b2bQVStatus = $employeeRowContainer.find('.b2b-qv-status-row:not(.b2b-incomplete-info)');
            var $availability = $employeeRowContainer.find('.b2b-qv-availability');
            if (!response.product.available) {
                if (response.product.availability.messages.length > 1) {
                    // Quantity greater than stock (only availability message we want displayed)
                    $availability.find('.b2b-qv-status').text(response.product.availability.messages);
                    $availability.show('fast');
                } else {
                    // Show OOS message, but not availability messaging
                    $availability.hide('fast');
                    $b2bQVStatus.show('fast');
                }
            } else {
                // Hide any existing OOS/availability message
                $availability.hide('fast');
                $b2bQVStatus.hide('fast');
            }

            // Update Variant Attributes
            $employeeRowContainer.find('.b2b-qv-attributes .b2b-qv-attribute').each(function(select) {
                var $select = $(this);
                // get data-attr value
                var attr = $select.data('attr');
                // find the div with the same data-attr value in the response and replace the html
                var $responseAttr = $(response.b2bVariantAttributeHTML).closest('div[data-attr="' + attr + '"]');
                if($responseAttr.length) {
                    // replace the html
                    $select.html($responseAttr.html());
                }
            });
        } else {
            $employeeProduct.val(response.product.id).attr('data-isready', false);
        }
        programQuickView.toggleATCButton();
    }
};

module.exports = programQuickView;