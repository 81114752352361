'use strict';

function onYotpoDiscountApplied() {
    $('body').on('yotpoLoyaltyDiscountApplied', function(e) {
        console.log(e);
        if (e && e.detail && e.detail.discountCode) {
            $('.promo-code-form .coupon-code-field').val(e.detail.discountCode);
            $('.promo-code-form .promo-code-btn').click();
        }
    });
}

module.exports = {
    onYotpoDiscountApplied: onYotpoDiscountApplied
};
