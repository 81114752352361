'use strict';

var base = require('core/product/quickView');
var modal = require('core/components/modal');
var wishlist = require('../wishlist/wishlist.js');

/**
 * Copied from core to modify the quick view HTML
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
 function fillModalElement(selectedValueUrl) {
    var $modal = $('#quickViewModal');
    $modal.find('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var parsedHtml = modal.parseHtml(data.renderedTemplate);

            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);
            $modal.find('.modal-footer').remove(); // We don't need the footer
            // $modal.find('.modal-footer').html(parsedHtml.footer);
            $modal.find('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $modal.find('.full-pdp-link').attr('href', data.productUrl);
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.find('#quickViewModal').modal('show');

            if (data.dialogTitle) {
                $modal.find('.modal-header').prepend('<div class="modal-title">' + data.dialogTitle + '</div>')
            }

            if (data.product.productType === 'set') {
                updateAddAllToCart();
            }

            $('body').trigger('modal:loaded', $('#quickViewModal')); // sending custom event for scroll body sizing
            $('body').trigger('quickview:ready', $('#quickViewModal')); // sending custom event for slider init
            $('body').trigger('tooltip:init');
            // Make heart icon accurate
            wishlist.updateWishlistLinkData();

            // render Klarna on-site message
            window.Klarna && window.Klarna.OnsiteMessaging && window.Klarna.OnsiteMessaging.refresh();

            $.spinner().stop();
        },
        error: function() {
            $.spinner().stop();
        }
    });
}

base.showQuickview = function showQuickview() {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        var selectedValueUrl = $(this).closest('a.quickview').attr('href');
        $(e.target).trigger('quickview:show');
        modal.getModalHtmlElement('quickViewModal', 'quick-view-dialog');
        fillModalElement(selectedValueUrl);
    });
};

module.exports = base;