'use strict';
var processInclude = require('base/util');
var floatingTool = require('../components/b2b/floatingTool');
var addProgramModal = require('../components/b2b/addProgramModal');
var pdpPrograms = require('../components/b2b/pdpPrograms');
var login = require('../components/b2b/login');
var programQuickView = require('../components/b2b/programQuickView');

function b2bGlobal () {
    $(document).ready(function () {
        processInclude(floatingTool);
        processInclude(addProgramModal);
        processInclude(pdpPrograms);
        processInclude(login);
        programQuickView.handleEvents();
    });
}

module.exports = b2bGlobal;