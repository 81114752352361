module.exports = function () {

    var $bisncontainer = $('.bisn-container');
    var $bisnform = $bisncontainer.find('#bisn-form');
    var $bisnlink = $bisncontainer.find('.bisn-link');
    var $bisnProductID = $bisncontainer.find('.bisn-product-id');
    var $bisnformcontainer = $bisncontainer.find('.bisn-form-container');
    var $bisnMsgContainer = $bisncontainer.find('.bisn-form-message');
    var $bisnMsgTxt = $bisnMsgContainer.find('.bisn-form-message-text');
    var $bisnMsgIcon = $bisnMsgContainer.find('.bisn-form-message-icon');
    var $bisnEmail = $bisnform.find('input[name="bisnEmail"]');


    function clearBISNForm() {
        // when loading this is initially hidden
        $bisnMsgContainer.addClass('hide');
        $bisnEmail.val('');
        $bisnMsgTxt.text('');
        $bisnMsgIcon.removeClass('icon-alert icon-check');
        $bisnMsgContainer.removeClass('success eror').addClass('hide');
        $bisnEmail.removeClass('is-valid is-invalid');
//        $bisnformcontainer.addClass('hide');
    }
    clearBISNForm();

    function handleErrorMsg(errorType) {
        const errorMsgs = {
            error: window.UncachedData.bisn.errorMsg,
            invalid: window.UncachedData.bisn.invalidMsg,
          };
          
          const type = errorType || 'error';
          const errorMsg = errorMsgs[type];
          
          $bisnMsgTxt.text(errorMsg);
          $bisnMsgIcon.addClass('icon-' + (type === 'error' ? 'alert' : 'check')).removeClass('icon-' + (type === 'error' ? 'check' : 'alert'));
          $bisnMsgContainer.addClass(type).removeClass('hide');
          $bisnEmail.addClass('is-invalid').removeClass('is-valid');
    }

    function handleSuccessMsg () {
        $bisnMsgTxt.text(window.UncachedData.bisn.successMsg);
        $bisnMsgIcon.addClass('icon-check').removeClass('icon-alert');
        $bisnMsgContainer.addClass('success').removeClass('hide');
        $bisnEmail.addClass('is-valid').removeClass('is-invalid');
    }

    $('body').on('product:updateAddToCart', function (e, response) {
        clearBISNForm();
        // Check if the $bisncontainer element exists
        if ($bisncontainer.length) {
            // Toggle visibility based on product availability

            if (response.product.isVariant && !response.product.available) {
                $bisncontainer.removeClass('hide');
            } else {
                $bisncontainer.addClass('hide');
            }

            // Set product ID if $bisnProductID element exists
            if ($bisnProductID.length) {
                $bisnProductID.val(response.product.id);
            }
        }
    });

    if ($bisnlink.length) {
        $bisnform.on('submit', function (e) {
            e.preventDefault();
            var $bisnform = $(this);

            // if product id is empty then do not submit
            if ($bisnProductID.val() === '') {
                return;
            }

            // if email is empty then do not submit
            if ($bisnEmail.val() === '') {
                return;
            }

            // submit form
            $.ajax({
                url: $bisnform.attr('action'),
                type: 'post',
                dataType: 'json',
                data: $bisnform.serialize(),
                success: function (data) {
                    if (data.success) {
                        handleSuccessMsg();
                    } else {
                        handleErrorMsg('error');
                    }
                },
                error: function (err) {
                    handleErrorMsg('error');
                }
            });
        });

        $bisnlink.on('click', function (e) {
            e.preventDefault();
            clearBISNForm();
            $bisnformcontainer.toggleClass('hide');
        });
    }
};