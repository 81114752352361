const creditCardNumberValidator = require("../checkout/creditCardNumberValidator");

module.exports = function () {
    var callback = function (e) {
        var inputName = this.name;
        var $input = $(this);
        var $formGroup = $input.closest('.form-group');

        // Base SFRA's clientSideValidation add's the is-invalid class if the field is invalid but does not clear it automatically
        // We can clear it here because if the input is still invalid, it will be added back
        $input.removeClass('is-invalid');
        $formGroup.removeClass('is-validation-ok is-validation-error');

        if (this.checkValidity()) {
            // If input is the 'password confirm' field then compare it to the password field
            if (inputName.endsWith('_passwordconfirm')) {
                 // Check if password matches if exist
                var $pass = $("[name$=_password]");
                if ($pass.length && $pass.val() === $input.val()) {
                    applyValidClass($input);
                    applyValidClass($pass);
                } else {
                    applyInvalidClass($input);
                    applyInvalidClass($pass);

                }
            } else if (inputName.endsWith('_customer_emailconfirm')) {
                // Check if emails match
                var $email = $("[name$=_customer_email]");
                if ($email.length && $email.val() === $input.val()) {
                    applyValidClass($input);
                    applyValidClass($email);
                } else {
                    applyInvalidClass($input);
                    applyInvalidClass($email);
                }
            } else if (inputName.endsWith('_newpasswordconfirm')) {
                // Check if emails match
                var $newPass = $("[name$=_newpassword]");
                if ($newPass.length && $newPass.val() === $input.val()) {
                    applyValidClass($input);
                    applyValidClass($newPass);
                } else {
                    applyInvalidClass($input);
                    applyInvalidClass($newPass);
                }
            } else if ($input.val() !== '') {
                applyValidClass($input);
            }

            // If input is the exp month or year make sure the card is not expired
            if (inputName.endsWith('_expirationMonth') || inputName.endsWith('_expirationYear')) {
                var $expMonthInput = $("[name$=_expirationMonth]");
                var $expYearInput = $("[name$=_expirationYear]");
                var expMonthValue = parseInt($expMonthInput.val());
                var expYearValue = parseInt($expYearInput.val());
                var currentYear = new Date().getFullYear();
                var currentMonth = new Date().getMonth(); //returns 0 - 11
                // Compare the date entered to the current month and year
                if((expMonthValue <= currentMonth) && (expYearValue <= currentYear)) {
                    //apply invalid class to both inputs
                    applyInvalidClass($expMonthInput);
                    applyInvalidClass($expYearInput);
                } else {
                    //apply valid class to both inputs
                    applyValidClass($expMonthInput);
                    applyValidClass($expYearInput);
                }
            }

            // validate credit card number
            if (inputName.endsWith('_cardNumber')) {
                var $cardNumberInput = $("[name$=_cardNumber]");
                var cardNumberValue = $cardNumberInput.val();
                var cardType = creditCardNumberValidator.getCardType(cardNumberValue);
                if (cardType) {
                    applyValidClass($cardNumberInput);
                } else {
                    applyInvalidClass($cardNumberInput);
                }
            }


        } else {
            if (inputName.endsWith('_emailSignup_email')) {
                if ($input.val() !== '') {
                    applyInvalidClass($input);
                }
                // Do not apply invalid class to email signup, if field is empty
            } else {
                applyInvalidClass($input);
            }
        }
    };

    var applyValidClass = function ($input) {
        // If $input is undefined then do nothing
        if (typeof($input) === 'undefined') { return };

        // Add the 'is-valid' class and remove 'is-invalid' to input
        $input.removeClass('is-invalid').addClass('is-valid');

        // Find the input form group and add the valid calss to group
        var $formGroup = $input.closest('.form-group');
        if ($formGroup.length) { $formGroup.removeClass('is-validation-error').addClass('is-validation-ok'); }
    };

    var applyInvalidClass = function ($input) {
        // If $input is undefined then do nothing
        if (typeof($input) === 'undefined') { return };

        // Add the 'is-invalid' class and remove 'is-valid' to input
        $input.removeClass('is-valid').addClass('is-invalid');

        // Find the input form group and add the invalid calss to group
        var $formGroup = $input.closest('.form-group');
        if ($formGroup.length) { $formGroup.removeClass('is-validation-ok').addClass('is-validation-error'); }
    };

    var $form = $('form.form-with-validation-icons');
    $form.on('focusout', 'input, select', callback);
    $form.on('custom:formValidationIcons', function () {
        $(this).find('input, select').each(callback);
    });
    $form.find('button[type="submit"]').on('click', function () {
        $(this).closest('form').find('input, select').each(callback);
    });
    $form.find('input[name$="_postalCode"]').on('keyup', function(){
        var $this = $(this);
        $this.val($this.val().toUpperCase())
    });
};
