'use strict';

var floatingTool = {
    handleEvents: function () {
        // Open/close programs floating tool
        $('.fp-tool-toggle').on('click', function () {
            $(this).toggleClass('closed');
            $(this).siblings('.fp-content').slideToggle();
        });

        // Load specific program, display items in program
        $('body').on('click', '.fp-program-item', function () {
            var $btn = $(this);
            var $tool = $btn.parents('.fp-content-inner');
            var url = $btn.attr('data-href');
            $tool.spinner('small').start();
            $.ajax({
                type: 'POST',
                url: url,
                success: function(response) {
                    if (response && response.success) {
                        var $modal = $('#programModal');

                        if ($modal.length !== 0) {
                            $modal.remove();
                        }

                        $tool.spinner().stop();
                        $('body').append(response.html);
                        $('#programModal').modal('show');
                    }
                },
                error: function(error) {
                    console.error(error);
                    $tool.spinner().stop();
                }
            });
        });

        // Toggle removal confirmation view
        $('body').on('click', '.pi-toggle-removal', function () {
            var $btn = $(this);
            var $row = $btn.parents('.program-item-row');
            $row.toggleClass('confirm-removal');
        });

        // Confirm removal of program item, reload items afterward
        $('body').on('click', '.pi-remove-confirmed', function () {
            var $btn = $(this);
            var url = $btn.attr('data-href');
            var $modal = $('#programModal');
            var $itemRow = $btn.parents('.program-item-row');
            $itemRow.spinner('small').start();
            $.ajax({
                type: 'POST',
                url: url,
                success: function(response) {
                    if (response && response.success) {
                        $modal.find('.program-item-list').replaceWith(response.html);
                        $itemRow.spinner().stop();
                    }
                },
                error: function(error) {
                    console.error(error);
                    $itemRow.spinner().stop();
                }
            });
        });
    }
};

module.exports = floatingTool;