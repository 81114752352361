/* globals google */
'use strict';

var SiteConstants = require('constants/SiteConstants');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Uses google maps api to render a map
 */
function maps() {
    var map;
    var infowindow = new google.maps.InfoWindow();
    var mapdiv = $('.map-canvas').attr('data-locations');
    var bounds = new google.maps.LatLngBounds();
    var zoom = 4;

    mapdiv = JSON.parse(mapdiv);

    if (mapdiv.length === 1) {
        zoom = 18; // set zoom more specifically if single store
    }

    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(37.09024, -95.712891);
    var mapOptions = {
        scrollwheel: false,
        zoom: zoom,
        center: latlng
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var label = parseInt(key, 10) + 1;
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);

        var marker = new google.maps.Marker({
            position: storeLocation,
            type: item.storeType.value,
            map: map,
            title: item.name,
            icon: {
                url: item.backgroundImage,
                size: new google.maps.Size(45, 45),
                scaledSize: new google.maps.Size(45, 45),
                labelOrigin: new google.maps.Point(24, 17)
            },
            label: {
                text: label.toString(),
                color: item.color,
                fontSize: '14px'
            }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);

        // If there is only one store, center the map on that store
        if (mapdiv.length === 1) {
            map.setCenter(storeLocation);
        }
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found, unless there is a single store result
    if (mapdiv && mapdiv.length > 1) {
        map.fitBounds(bounds);
    }
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    var $resultsDiv = $('.results');
    var $mapDiv = $('.map-canvas');
    var hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('.store-locator-no-results').show();
        $('.filter-results').hide();
        $resultsDiv.attr('data-has-results', false);
    } else {
        $('.store-locator-no-results').hide();
        $('.filter-results').show();
        $resultsDiv.attr('data-has-results', true);
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey)
        .data('store-type', data.storeType);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
    }

    if (data.noInventoryStoresHtml) {
        $resultsDiv.append(data.noInventoryStoresHtml);
    }


    if (hasResults) {
        $.each(data.storeTypes, function(storeType) {
            var storeTypeValue = data.storeTypes[storeType].value ||data.storeTypes[storeType].value
            if ($('.results [data-store-type="' + storeTypeValue + '"]').length > 0) {
                $('.filter-results [data-filter="' + storeTypeValue + '"]').show();
            }
        });
    }
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
    var dialog = element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $form = element.closest('.store-locator');
    var radius = $('.results').data('radius');
    var url = $form.attr('action');
    var urlParams = {
        radius: radius
    };

    var payload = $form.is('form') ? $form.serialize() : {
        postalCode: $form.find('[name="postalCode"]').val()
    };

    var cleanPayload = decodeURI(payload).replace(/\s/g, '')
    url = appendToUrl(url, urlParams);
    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: cleanPayload,
        dataType: 'json',
        success: function (data) {
            updateStoresResults(data);
            $('.select-store').prop('disabled', true);
        },
        complete: function () {
            spinner.stop();
        }
    });
    return false;
}

module.exports = {
    init: function () {
        if ($('.map-canvas').data('has-google-api')) {
            maps();
        } else {
            $('.store-locator-no-apiKey').show();
        }
    },

    detectLocation: function () {
        // clicking on detect location.
        $('.detect-location').on('click', function () {
            $.spinner().start();
            if (!navigator.geolocation) {
                $.spinner().stop();
                return;
            }

            navigator.geolocation.getCurrentPosition(function (position) {
                var $detectLocationButton = $('.detect-location');
                var url = $detectLocationButton.data('action');
                var radius = $('.results').data('radius');
                var urlParams = {
                    radius: radius,
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                };

                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $.spinner().stop();
                        updateStoresResults(data);
                        $('.select-store').prop('disabled', true);
                        $('.detect-location-invalid').removeClass('d-block');
                    }
                }).fail(function(){
                    $.spinner().stop();
                });
            },
            function () {
                $.spinner().stop();
                $('.detect-location-invalid').addClass('d-block');
            }, {
                timeout: 5000
            });
        });
    },

    search: function () {
        $('.store-locator-container form.store-locator').submit(function (e) {
            e.preventDefault();
            search($(this));
        });
        $('.store-locator-container .btn-storelocator-search[type="button"]').click(function (e) {
            e.preventDefault();
            search($(this));
        });
    },
    changeRadius: function () {
        $('.store-locator-container .radius').change(function () {
            var radius = $(this).val();
            var searchKeys = $('.results').data('search-key');
            var url = $(this).data('action-url');
            var urlParams = {};

            if (searchKeys.postalCode) {
                urlParams = {
                    radius: radius,
                    postalCode: searchKeys.postalCode
                };
            } else if (searchKeys.lat && searchKeys.long) {
                urlParams = {
                    radius: radius,
                    lat: searchKeys.lat,
                    long: searchKeys.long
                };
            }

            $('.filter-results [data-filter]').removeClass('active btn-primary').addClass('btn-secondary').hide();
            $('.filter-results [data-filter="all"]').addClass('active btn-primary').removeClass('btn-secondary').show();

            url = appendToUrl(url, urlParams);
            var dialog = $(this).closest('.in-store-inventory-dialog');
            var spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    updateStoresResults(data);
                    $('.select-store').prop('disabled', true);
                },
                complete: function () {
                    spinner.stop();
                }
            });
        });
    },
    filterResults: function () {
        var $filters = $('.filter-results [data-filter]');

        $filters.on('click', function (e) {
            e.preventDefault();

            $filters.removeClass('active btn-primary').addClass('btn-secondary');
            $(this).addClass('active btn-primary').removeClass('btn-secondary');

            var $filterType = $(this).attr('data-filter');
            var radius = $('.results').data('radius');
            var searchKeys = $('.results').data('search-key');
            var url = $('.store-locator-container .filter-results').data('action-url');
            var urlParams = {};

            if (searchKeys.postalCode) {
                urlParams = {
                    radius: radius,
                    postalCode: searchKeys.postalCode,
                    storeType: $filterType
                };
            } else if (searchKeys.lat && searchKeys.long) {
                urlParams = {
                    radius: radius,
                    lat: searchKeys.lat,
                    long: searchKeys.long,
                    storeType: $filterType
                };
            }

            url = appendToUrl(url, urlParams);
            var dialog = $('.store-locator-container .radius').closest('.in-store-inventory-dialog');
            var spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    updateStoresResults(data);
                    $('.select-store').prop('disabled', true);
                },
                complete: function () {
                    spinner.stop();
                }
            });

        });
    },
    selectStore: function () {
        $('.store-locator-container').on('click', '.select-store', (function (e) {
            e.preventDefault();
            var selectedStore = $(':checked', '.results-card .results');
            var data = {
                storeID: selectedStore.val(),
                searchRadius: $('#radius').val(),
                searchPostalCode: $('.results').data('search-key').postalCode,
                storeDetailsHtml: selectedStore.siblings('label').find('.store-details').html(),
                event: e
            };

            $('body').trigger('store:selected', data);
        }));
    },
    updateSelectStoreButton: function () {
        $('body').on('change', '.select-store-input', (function () {
            $('.select-store').prop('disabled', false);
        }));
    }
};
