'use strict';
const base = require('core/cart/cart');
const productBase = require('../product/base');

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
 function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    $('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    $('.tax-total span').empty().append(data.totals.totalTax);
    $('.grand-total-sum').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems).attr('data-quantity', data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    // Update cart title wtih quanity
    $('.cart-page-title').text(data.pageTitle);

    // Show empty cart
    if (data.numItems === 0) {
        $('.empty-cart-content').show();
        $('.cart-items-header').hide();
        $('.cart-content').hide();
        $('.empty-cart-container').hide();
        $('.order-totals-card').hide();
        $('.promo-code-card').hide();
        $('.checkout-continue').hide();
        $('.totals-card').hide();
    }

    $('.shipping-total-cost span').text(data.totals.totalShippingCost);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0 && !data.totals.hasFreeShipping) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $('.giftcertificate-discount').removeClass('d-none');
        $('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $('.giftcertificate-discount-total').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }

        if (item.customizer && item.customizer.isCustomized) {
            var $item = $('.cart-product-card[data-uuid="' + item.UUID + '"]');
            item.customizer.addonProducts.forEach(function (addonItem) {
                if (addonItem) {
                    var addonItemHTML = '';
                    var $addonContainer = $('[data-customizer-addon-product-uuid="'+addonItem.uuid+'"] .addon-product-price-container');
                    if (addonItem.hasDiscount) {
                        $addonContainer.addClass('text-nowrap');
                        addonItemHTML = `<span class="addon-product-price strike-through">+${addonItem.price.formatted}</span>
                                         <span class="addon-product-sales-price">+${addonItem.salesPrice.formatted}</span>`;
                    } else {
                        $addonContainer.removeClass('text-nowrap');
                        addonItemHTML = `<span class="addon-product-price">+${addonItem.salesPrice.formatted}</span>`;
                    }
                    $addonContainer.html(addonItemHTML);
                }
            });
            $('.item-total-' + item.UUID + ' .line-item-total-price-amount').text(item.customizer.priceWithCustomizations.adjusted.formatted);

            if (item.customizer.approachingCustomizationDiscounts && item.customizer.approachingCustomizationDiscounts.length) {
                $item.find('.line-item-approaching-discounts').show();
                var approachingDiscountsHtml = '';
                item.customizer.approachingCustomizationDiscounts.forEach(function(discount) {
                    var $tooltip = $('<span class="tooltip-whats-this text-nowrap" data-html="true" data-toggle="tooltip"></span></div>')
                        .text(discount.tooltipLink)
                        .attr('title', '<div class="text-left">' + discount.tooltipBody + '</div>')
                    var tooltipHtml = $('<div>').append($tooltip).html();
                    tooltipHtml = '<div class="text-right ml-2">' + tooltipHtml + '</div>';
                    approachingDiscountsHtml = approachingDiscountsHtml
                        + '<div class="custom-approaching-discount warning-text d-flex flex-column flex-lg-row justify-content-between"><span>'
                        + discount.discountMsg
                        + '</span>'
                        + tooltipHtml
                        + '</div>';
                });
                $item.find('.line-item-approaching-discounts .col-12').html(approachingDiscountsHtml);
                $('body').trigger('tooltip:init');
            } else {
                $item.find('.line-item-approaching-discounts').hide();
            }
        } else {
            $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }
    });

    $('body').trigger('unavailableProducts:update', data);

    if (data.totals.klarnaTotal) {
        $('klarna-placement').attr('data-purchase-amount',
        data.totals.klarnaTotal);
        window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
        window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
    }
}

base.updateCartTotals = updateCartTotals;

/**
 * Updated from base to prevent removing the `.cart` html when the basket is empty
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error || !data.mojoInventoryInfo.isOrderable) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.valid-cart-error .close').trigger('click');
        }

        if (data.mojoInventoryInfo.errorMsg) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.mojoInventoryInfo.errorMsg + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.mojo-inventory-alert .close').trigger('click');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
        $('.valid-cart-error .close').trigger('click');
    }
}

base.validateBasket = validateBasket;

function updateProductSummary(data) {
    $('.order-product-summary .card-title a').empty().append(data.yourItemsLabel);
    $('.order-product-summary .details-container .qty').empty().append(data.inlineQtyLabel + ': ' + data.numItems);
    $('.order-product-summary .details-container .line-item-total-price-amount').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
}

base.updateProductSummary = updateProductSummary;

/**
 * Modified from base to update the `.cart-delete-confirmation-btn` to prevent removing the `.cart` html
 * when the basket is empty
 */
function init () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                // WA-130 - Modified base code so the entire cart HTML isn't emptied
                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                        $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                    }
                }
                $('.uuid-' + uuid).closest('.card').remove();
                if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                }
                // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                module.exports.updateCartTotals(data.basket);
                module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                module.exports.validateBasket(data.basket);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var $select = $stepper.closest('.quantity-form').find('.quantity');
        var value = parseInt($stepper.find('input').val());

        $select.find('option[value="' + value + '"]').prop('selected', true);

        if (value >= $stepper.data('modal-min')) {
            $('#quantityFormModal').modal('show');
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.cart-product-card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateProductSummary(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    //Enable the modal to show larger customizer image in the cart

    $('body').on('click', '.customized-image-launcher', event => {
        event.preventDefault();
        // match the id so we launch the modal with the correct product image inside
        var lineItemUUID = $(event.target).closest('.item-image-container').find('.line-item-uuid').html();
        var $customizerImageModal = $('.modal[data-product="' + lineItemUUID + '"]');

        $customizerImageModal.modal({backdrop:false, show:true,});
    });


    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option-value-id', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        form.pid = module.exports.getPidValue($(this))

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#quickViewModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    module.exports.updateCartTotals(data.cartModel);
                    module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    module.exports.updateAvailability(data.cartModel, form.uuid);
                    module.exports.updateProductDetails(data, form.uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    module.exports.validateBasket(data.cartModel);

                    $('body').trigger('cart:update', [data, form.uuid]);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });

    $('body').on('cart:saveforlater:update', function(e ) {
        productBase.enableQuantitySteppers();

    });
}

base.init = init;

module.exports = base;
