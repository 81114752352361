'use strict';

module.exports = {
    handleTabVideos: () => {
        var $tabPane = $('.tab-pane');
        var $videos = $tabPane.find('.hero-media.video-container, .background-image.video-container, .experience-commerce_assets-video>.video-container');

        if ($videos.length) {
            $('a[data-toggle="tab"]').on('show.bs.tab', event => {
                var $newTab = $(event.target.hash);
                var newTabVideoId = $newTab.find('.video-player').attr('id');
                var videoToPlay = window.Players[newTabVideoId];
                var $oldTab = $(event.relatedTarget.hash);
                var oldTabVideoId = $oldTab.find('.video-player').attr('id');
                var videoToPause = window.Players[oldTabVideoId];

                if (videoToPause) {
                    videoToPause.pause();
                }

                if (videoToPlay) {
                    videoToPlay.play();
                }
            });
        }
    }
};
