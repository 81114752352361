'use strict';

module.exports = {
	postOrderDetails: function() {
		$('.postorder-details-form input[type="radio"][name="dwfrm_confirmation_accountuse"]').change( function() {
			if (this.value) {
				$('.postorder-details-form button.submit').prop('disabled', false);
			}
		});
		$('.postorder-details-form').on('submit', function(event) {
			var form = $(this);
			event.preventDefault();
			var url = form.attr('action') + '?' + form.serialize();
			form.spinner().start();
			form.trigger('postorderdetails:submit', event);
			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				data: form.serialize(),
				success: function(data) {
					form.spinner().stop();
					$('.order-confirmation-card .card-body').css('visibility', 'hidden');
					$('.order-confirmation-card .title').html(data.response.title);
					$('.order-confirmation-card .sub-title').html(data.response.subtitle);
				},
				error: function() {
					form.spinner().stop();
				}
			});
		});
	}
};

