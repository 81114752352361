// Fire on load event to prevent getting the banner height before the layout is done rendering
window.addEventListener('load', function () {
    var pageMarginTop = $('.page').css('margin-top');
    // Only use the margin-top if it's a pixel value
    if (!pageMarginTop || !pageMarginTop.includes('px')) {
        return;
    }
    pageMarginTop = Number(pageMarginTop.slice(0, -2));

    var fixedHeaderHeight = $('.fixed-header').height();
    // Only adjust the .page margin-top if the height difference is too big
    if (Math.abs(fixedHeaderHeight - pageMarginTop) >= 3) {
        $('.page').css('margin-top', fixedHeaderHeight + 'px');
    }
});

module.exports = require('core/components/menu');
