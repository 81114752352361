'use strict';

// INOTE: Had to bring this file over to disable the hardcoded
// class check applying max height to modals. 2/13/22

var base = require('core/components/modal');

function onShownBsModal() {
    $('body').on('shown.bs.modal', '.modal', function() {
        $('body').trigger('modal:loaded', this);
    });
}

function onModalLoaded() {
    $('body').on('modal:loaded', function(event, modal) {
        var $modal = $(modal);

        // Check for modal-scrollbody helper class and adjust body max-height if found

        // INOTE: Had to bring this file over just to disable the commented out line below
        if ($modal.find('.modal-scrollbody').length
            // || $modal.find('.quick-view-dialog').length
            || $modal.find('.choose-bonus-product-dialog').length) {
            base.setModalBodyMaxHeight($modal);
        }
    });
}

function initCustomModal() {
    $('body').on('click', '.custom-modal', function (e) {
        e.preventDefault();
        var cid = $(this).attr('data-cid');
        var title = $(this).attr('data-title');
        var dialogClasses = $(this).attr('data-dialog-classes');
        var selectedValueUrl = $(this).attr('href');

        getModalHtmlElement('customModal', dialogClasses);
        fillModalElement(selectedValueUrl, {cid: cid, title: title}, $('#customModal'));
    });
}

base.onModalLoaded = onModalLoaded;
base.init = function() {
    onShownBsModal();
    onModalLoaded();
    initCustomModal();
};

module.exports = base;