'use strict';

module.exports = {
	unavailableProductOptions: function() {
		$('body').on('click', '.unavailable-decrease-quantity', function (e) {
			var $this = $(this);
			var newQuantity = $this.data('available-quantity');
			var $productCard = $this.closest('.unavailable-product-line-item');

			$productCard.find('[name="qty-stepper"]').val(newQuantity).data('qty', newQuantity).trigger('change');
		});
		$('body').on('click', '.unavailable-remove-product', function (e) {
			var $this = $(this);
			var $productCard = $this.closest('.unavailable-product-line-item');

			$productCard.find('.remove-product').click();
		});
	},
	unavailableProductsUpdate: function() {
		$('body').on('unavailableProducts:update', function (e, data) {
			console.log('unavailableProducts:update', data);
			if (data && data.items && data.items.length) {
				data.items.forEach(function (item) {
					if (item.mojoInventoryInfo && item.mojoInventoryInfo.available === false) {
						$('.cart-product-card[data-uuid='+ item.UUID +']').addClass('unavailable-product-line-item');
					} else {
						$('.cart-product-card[data-uuid='+ item.UUID +']').removeClass('unavailable-product-line-item');
					}
				});
			}
		});
	}
};