'use strict';

module.exports = {
	checkoutSpinner: function() {
        $('body').on('checkout:disableButton', function (e, button) {
			$.spinner().start();
		});
	
		$('body').on('checkout:enableButton', function (e, button) {
			$.spinner().stop();
		});
	}
};