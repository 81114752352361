'use strict';

const baseCustomizer = require('client_core/customizer/customizerHelper');
const customizer = require('client_core/customizer/customizer');

var selectModal = {
    updateProgramList: function(response) {
        if (!response) return;
        // get  $('#selectProgramModal .program-item.sample')
        var $sampleTemplate = $('#selectProgramModal .program-item.sample').clone().removeClass('sample').removeClass('d-none');

        // iterate through response.programs and replace the sample template with the program data
        // for each program, clone the sample template, replace the values, and append it to the program list
        var $programList = $('#selectProgramModal .program-list');
        $programList.empty();

        $.each(response.programs, function(index, program) {
            var $program = $sampleTemplate.clone();
            // replace the values in double curly braces with the program data
            $program.html($program.html().replace(/{{programID}}/g, program.ID));
            $program.html($program.html().replace(/{{programName}}/g, program.name));
            $program.html($program.html().replace(/{{itemCount}}/g, program.itemCount));
            $program.html($program.html().replace(/{{programURL}}/g, program.url));
            $programList.append($program);
        });
    },
    getProgramList: function () {
        var $btn = $('.product-program-button');
        var url = $btn.attr('href');
        $.ajax({
            type: 'POST',
            url: url,
            success: function(response) {
                if (response && response.success) {
                    selectModal.updateProgramList(response);
                }
            },
            error: function(error) {
                console.error(error);
            }
        });
    },
    prepareModal: function (afterCustomizer) {
        var $modal = $('#selectProgramModal');
        var $addToProgram = $modal.find('.add-to-program-btn');
        selectModal.getProgramList();
        if (afterCustomizer) {
            // After customizer
            $modal.find('.customize-item-btn').hide();
            $addToProgram.addClass('post-customizer');
        } else {
            // Before customizer or without customizer entirely
            $modal.find('.customize-item-btn').show();
            $addToProgram.removeClass('post-customizer');
        }
    },
    getProgramIDs: function () {
        var $modal = $('#selectProgramModal');
        var $selectedPrograms = $modal.find('input[name="programID"]:checked');
        var programIDs = [];
        // Get list of programIDs
        $selectedPrograms.each(function() {
            programIDs.push($(this).val());
        });
        return programIDs;
    },
    clearSelected: function () {
        var $modal = $('#selectProgramModal');
        var $selectedPrograms = $modal.find('input[name="programID"]:checked');
        $selectedPrograms.prop('checked', false);
    }
};

function prepareAddProductToProgramModal(response) {
    var $modal = $('#addedToProgramModal');
    $modal.find('.atp-item-row').replaceWith(response.html);
    $modal.modal('show');
}

function addProductToProgram(data, url) {
    if (!data || !url) return;
    var $selectProgramAlertError = $('#selectProgramAlertError');
    $selectProgramAlertError.html('').hide();
    $.ajax({
        type: 'POST',
        url: url,
        data: data,
        success: function(response) {
            if (response.success) {
                $('#selectProgramModal').modal('hide');
                selectModal.clearSelected();
                prepareAddProductToProgramModal(response);
            } else {
                $selectProgramAlertError.html(response.message).show();
            }
        },
        error: function(error) {
            $selectProgramAlertError.html(error).show();
            console.error('Add To Program Error: ', error);
        }
    });
}

function addCustomProductToProgram(recipe, url) {
    baseCustomizer.customizerSpinner.start();
    var $selectProgramAlertError = $('#selectProgramAlertError');
    $selectProgramAlertError.html('').hide();
    $.ajax({
        type: 'POST',
        url: url,
        data: recipe,
        traditional: true, // Serialize arrays during request
        success: function(response) {
            if (response.success) {
                // Hide customizer if it isn't already hidden
                $('#customizerModal').modal('hide');
                // Hide select programs modal it isn't already hidden
                $('#selectProgramModal').modal('hide');
                selectModal.clearSelected();
                // Show added modal
                prepareAddProductToProgramModal(response);
            } else {
                if (response.message === 'NOT_CUSTOMIZABLE') {
                    $('#customizerModal').modal('hide');
                    $('#customizerErrorModal').modal('show');
                }
                $selectProgramAlertError.html(response.message).show();
            }
            baseCustomizer.customizerSpinner.stop();
        },
        error: function(error) {
            console.error('Add To Program Error: ', error);
            baseCustomizer.customizerSpinner.stop();
            $('#customizerModal').modal('hide');
            $selectProgramAlertError.html(error).show();
        }
    });
}

var pdpPrograms = {
    handleEvents: function () {
        const $doc = $(document);
        // Get the list of programs for the current customer
        $doc.on('click', '.product-program-button', function(e) {
            e.preventDefault();
            // get value  data-product-id and update input[name="productID"] with it
            var $btn = $(this);
            var pid = $btn.attr('data-product-id');
            var $programVariantInput = $('#selectProgamForm input[name="productID"]');
            if (pid && $programVariantInput.length > 0) {
                $programVariantInput.val(pid);
            }

            var $customizeItemBtn = $('#selectProgamForm .customize-item-btn');
            if ($customizeItemBtn.length > 0 && $btn.attr('data-customizer-apikey')) {
                $customizeItemBtn.attr('data-customizer-apikey', $btn.attr('data-customizer-apikey'));
                $customizeItemBtn.attr('data-customizer-get-variants-endpoint', $btn.attr('data-customizer-get-variants-endpoint'));
                $customizeItemBtn.attr('data-customizer-get-artwork', $btn.attr('data-customizer-get-artwork'));
                $customizeItemBtn.attr('data-customizer-product-id', $btn.attr('data-customizer-product-id'));
                $customizeItemBtn.attr('data-customizer-add-to-program', $btn.attr('data-customizer-add-to-program'));
                $customizeItemBtn.attr('data-customizer-format-price', $btn.attr('data-customizer-format-price'));
                $customizeItemBtn.attr('data-customizer-product-data', $btn.attr('data-customizer-product-data'));
                $customizeItemBtn.attr('data-customizer-is-master', $btn.attr('data-customizer-is-master'));
                $customizeItemBtn.attr('data-customizer-show-program', $btn.attr('data-customizer-show-program'));
                $customizeItemBtn.attr('data-customizer-lot', $btn.attr('data-customizer-lot'));
            }

            // prepare the modal
            if ($btn.hasClass('tile-program-btn')) {
                // Need to hide/show modal's customizable button if on the PLP
                customizer.checkCustomizableStatusAndUpdateButtons($btn).then(function () {
                    selectModal.prepareModal(false);
                });
            } else {
                selectModal.prepareModal(false);
            }
        });

        $doc.on('submit', '#selectProgamForm', function(e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var data = $form.serialize();

            // check for checked radio button in the program list and if not checked return to the user
            var $checkedRadio = $('#selectProgramModal input[name="programID"]:checked');
            if ($checkedRadio.length === 0) {
                $('#selectProgramAlertError').html('Please select a program.').show();
                return;
            }

            addProductToProgram(data, url);
        });

        $('body').on('product:afterAttributeSelect', function (e, response) {
            var programVariantInput = $('#selectProgamForm input[name="productID"]');
            if (programVariantInput.length > 0) {
                programVariantInput.val(response.data.product.programVariantID);
            }
        });

        // "create program" link click event for add "selectProgramModal" modal on the PDP/PLP
        $doc.on('click', '#selectProgramModal .program-add-modal-link', function(e) {
            e.preventDefault();
            const $inputWrapper = $('.create-program-input');
            $inputWrapper.toggleClass('open');
        });

        $doc.on('click', '#selectProgramModal .create-program-link', function(e) {
            e.preventDefault();
            var $programName = $('.create-program-input .program-name');
            pdpPrograms.createProgram.call(this, $programName.val());
        });

        // Program selection modal events
        $doc.on('click', '.add-to-program-btn.post-customizer', function(e) {
            e.preventDefault();
            var $modal = $('#selectProgramModal');
            var addCustomToProgramUrl = $('[data-customizer-add-to-program]').data('customizer-add-to-program');
            var recipe = $modal.data('recipe');
            var pid = $modal.data('pid');

            // Submit request to add custom product to the program
            var data = {
                programID: selectModal.getProgramIDs(),
                productID: pid,
                recipeID: recipe.id,
                recipeLocation: recipe.location
            };
            addCustomProductToProgram(data, addCustomToProgramUrl);
        });

        // Re-initialize the carousel when modal is displayed
        $('#addedToProgramModal').on('shown.bs.modal', function () {
            var $slider = $(this).find('.slider-container');
            var tns = $slider.data('tns');
            if (tns) {
                tns.destroy();
                tns = tns.rebuild();
                $slider.data('tns', tns);
            }
        })

        $('body').on('tile:swatchSelected', function(e, swatch) {
            var $swatch = $(swatch);
            var $tileParent = $swatch.closest('.product-tile');
            var lot = $swatch.attr('data-lot');
            $tileParent.find('.product-program-button').attr('data-customizer-lot', lot);

            var pid = $swatch.data('pid');
            if (pid) {
                $tileParent.find('.product-program-button').attr('data-product-id', pid);
            }
        });
    },
    createProgram: function(name) {
        if (!name) return;
        var $this = $(this);
        var url = $this.attr('href');

        var $selectProgramAlertError = $('#selectProgramAlertError');
        $selectProgramAlertError.html('').hide();
        var $selectProgramAlertSuccess = $('#selectProgramAlertSuccess');
        $selectProgramAlertSuccess.html('').hide();

        $.ajax({
            type: 'POST',
            url: url,
            data: {
                programName: name,
                responseTemplate: 2
            },
            success: function(response) {
                if (response && response.success) {
                    selectModal.updateProgramList(response);
                    $selectProgramAlertSuccess.html(response.message).show();
                } else {
                    $selectProgramAlertError.html(response.message).show();
                }
            },
            error: function(error) {
                console.error('Create Program Error: ', error);
                $selectProgramAlertError.html(error).show();
            }
        });
    },
    initCustomizer: function() {
        const $doc = $(document);
        var apiKey = $('[data-customizer-apikey]').data('customizer-apikey');
        var getVariantsEndpoint = $('[data-customizer-get-variants-endpoint]').data('customizer-get-variants-endpoint');
        var getArtworkUrl = $('[data-customizer-get-artwork]').data('customizer-get-artwork');
        var productId = $('[data-customizer-product-id]').data('customizer-product-id');
        var programVariantInput = $('#selectProgamForm input[name="productID"]');
        var addCustomToProgramUrl = $('[data-customizer-add-to-program]').data('customizer-add-to-program');
        var formatPriceUrl = $('[data-customizer-format-price]').data('customizer-format-price');
        var productDataUrl = $('[data-customizer-product-data]').data('customizer-product-data');
        var isMaster = $('[data-customizer-is-master]').data('customizer-is-master');
        var showProgram = $('[data-customizer-show-program]').data('customizer-show-program');
        var lot = $('[data-customizer-lot]').data('customizer-lot');

        var $selectProgramModal = $('#selectProgramModal');

        if(isMaster == true || isMaster == 'true') {
            $('.product-detail').attr('pid', '');
        }

        if (apiKey) {
            $doc.on('click', '.customize-item-btn', function(e) {
                e.preventDefault();
                var $this = $(this);
                // check for checked radio button in the program list and if not checked return to the user
                var $selectedPrograms = $selectProgramModal.find('input[name="programID"]:checked');
                if ($selectedPrograms.length === 0) {
                    $('#selectProgramAlertError').html('Please select a program.').show();
                    return;
                }

                // if data-customizer-* is set on this button then use those values instead of the default values
               // values to set:
                if ($this.attr('data-customizer-apikey')) {
                    apiKey = $this.attr('data-customizer-apikey');
                }
                if ($this.attr('data-customizer-get-variants-endpoint')) {
                    getVariantsEndpoint = $this.attr('data-customizer-get-variants-endpoint');
                }
                if ($this.attr('data-customizer-get-artwork')) {
                    getArtworkUrl = $this.attr('data-customizer-get-artwork');
                }
                if ($this.attr('data-customizer-product-id')) {
                    productId = $this.attr('data-customizer-product-id');
                }
                if ($this.attr('data-customizer-add-to-program')) {
                    addCustomToProgramUrl = $this.attr('data-customizer-add-to-program');
                }
                if ($this.attr('data-customizer-format-price')) {
                    formatPriceUrl = $this.attr('data-customizer-format-price');
                }
                if ($this.attr('data-customizer-product-data')) {
                    productDataUrl = $this.attr('data-customizer-product-data');
                }
                if ($this.attr('data-customizer-is-master')) {
                    isMaster = $this.attr('data-customizer-is-master');
                }
                if ($this.attr('data-customizer-show-program')) {
                    showProgram = $this.attr('data-customizer-show-program');
                }
                if ($this.attr('data-customizer-lot')) {
                    lot = $this.attr('data-customizer-lot');
                }

                baseCustomizer.customizerSpinner.start();

                // Get yotpo stars
                var startHtml = $('.product-rating-stars').first().html();
                $('.customizer-stars-placeholder').html(startHtml);

                $('#bc-chat-container, .bcFloat').addClass('d-none');
                baseCustomizer.handleRaceCondition();

                $selectProgramModal.modal('hide');
                $('#customizerModal').modal('show');
                $('#customize__placeholder').append('<div></div>');

                // eslint-disable-next-line no-undef
                CustomizerWidget.default.createWidget({
                    apiKey: apiKey,
                    products: productId,
                    allowPrograms: showProgram,
                    mode: 'program',
                    container: $('#customize__placeholder div')[0],
                    getVariantsEndpoint: getVariantsEndpoint,
                    formatPriceEndpoint: formatPriceUrl,
                    productDataEndpoint: productDataUrl,
                    artworkEndpoint: getArtworkUrl,
                    allowProgram: showProgram,
                    selectedLot: (lot !== '') ? lot : baseCustomizer.getSelectedLotValue(),
                    selectedVariant: baseCustomizer.getSelectedVariantValue(),
                    onSizingChart: function() {
                        // if the sizechart is from customizer append after all the modal-backdrops
                        var modalProductID = $('.product-detail').find('.product-id').html();
                        var $sizeChartModal = $('.modal[data-product="' + modalProductID + '"]');
                        var $sizeChartContainer = $('.product-detail').find('.size-chart .sizechart-link').parent(); // brute force it to select PDP size chart
                        $sizeChartModal.appendTo('body');
                        $sizeChartModal.one('hide.bs.modal', event => {
                            $sizeChartModal.appendTo($sizeChartContainer);
                        });
                        // Base AB adds a max-height to the quickview modal, which is also applied to the size chart modal
                        // Reset the max-height when launching the size chart modal
                        $sizeChartModal.find('.modal-body').css('max-height', '');

                        // Autobahn sets the modal title when the quickview is launched, and because the size chart
                        // is contained in the quickview modal the title is also applied to the size chart.
                        // Remove the title from the size chart modal
                        $sizeChartModal.find('.modal-title').remove();

                        $('#sizeChartModal').modal('show');
                    },
                    onBusinessOrder: function() {
                        $('#quantityFormModal').modal('show');
                    },
                    onCancel: function() {
                        $('#customizerModal').modal('hide');
                        $('#bc-chat-container, .bcFloat').removeClass('d-none');
                    },
                    onBusy: function(isBusy) {
                        if (isBusy) {
                            baseCustomizer.customizerSpinner.start();
                        } else {
                            baseCustomizer.customizerSpinner.stop();
                        }
                    },
                    onDone: function(result) {
                        $doc.trigger('customizer:programAdd',  { result: result });
                    }
                });
            });

            $doc.on('customizer:clearSelectedPrograms', function (e, data) {
                selectModal.clearSelected();
            })

            $doc.on('customizer:programAdd', function (e, data) {
                var result = data.result;
                var $selectedPrograms = $selectProgramModal.find('input[name="programID"]:checked');
                var pid = programVariantInput.length ? programVariantInput.val() : productId;
                // if $selectedPrograms.length === 0 then we need to open the select program modal. 
                // otherwise we need to add the product to the program
                if ($selectedPrograms.length === 0) {
                    // set the recipe and product id
                    $selectProgramModal.data('recipe', result.recipe)
                    $selectProgramModal.data('pid', pid);
                    // prepare for after-customization view of program selection
                    selectModal.prepareModal(true);
                    // close customizer and open the select program modal
                    $('#customizerModal').modal('hide');
                    $selectProgramModal.modal('show');
                } else {
                    // add the product to the program
                    var data = {
                        programID: selectModal.getProgramIDs(),
                        productID: pid,
                        recipeID: result.recipe.id,
                        recipeLocation: result.recipe.location
                    };
                    addCustomProductToProgram(data, addCustomToProgramUrl);
                }

                $("#customizerModal").one("hidden.bs.modal", function () {
                    // put your default event here
                    $('#bc-chat-container, .bcFloat').removeClass('d-none');
                });
            })
        }
    }
};

module.exports = pdpPrograms;