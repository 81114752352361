'use strict';

module.exports = function() {
    $(document).ready(function() {
        // Get the current URL
        var currentUrl = window.location.href;

        // Iterate through each tab link
        $('.horizontal-tab-link').each(function() {
            var tabLink = $(this).attr('href');

            console.log('currentUrl: ' + currentUrl);
            console.log('tabLink: ' + tabLink);
            // Check if the tab link matches the current URL
            if (currentUrl.includes(tabLink)) {
                $(this).addClass('active');
            }
        });
    });
}