'use strict';

var tinySlider = require('tiny-slider/src/tiny-slider');
var abSlider = require('core/components/slider');

abSlider.historySliderInit = function ($container, params) {
    var slider = tinySlider.tns(params);

    if (!!slider) {
        console.log('Got the slider instance');
        slider.events.on('transitionStart', this.slideIntoView);

        this.bindPauseButton($container, slider);
        this.bindSliderUpdateEvent($container, slider);
        this.enableImageZoom($container);
        this.handleThumbnailArrows($container, slider);
        this.handleSliderVideos($container, slider);

        $('body').trigger('slider:init.sliderEvents', [$container]);

        var $sliderContainer = $container.closest('.js-history-slider');

        $sliderContainer.find('.js-history-slider-prev').on('click', function (e) {
            slider.goTo('prev');
        });
        $sliderContainer.find('.js-history-slider-next').on('click', function (e) {
            slider.goTo('next');
        });

        var updateSlider = function (info) {
            var currentSlide = $(info.slideItems[info.index]).children();

            $sliderContainer.find('.js-history-slide-title').text(currentSlide.data('title'));
            $sliderContainer.find('.js-history-slide-subtitle').text(currentSlide.data('subtitle'));

            var $indicators = $sliderContainer.find('.js-history-slider-indicators').children();
            $indicators.removeClass('history-slider-indicator--active');
            $($indicators[info.displayIndex - 1]).addClass('history-slider-indicator--active');
        }

        slider.events.on('transitionStart', function (info, eventName) {
            updateSlider(info);
        });

        updateSlider(slider.getInfo());

        // Add indicators
        var pages = slider.getInfo().pages;
        var $indicators = $sliderContainer.find('.js-history-slider-indicators');
        for (var i = 0; i < pages; i++) {
            var $indicator = $(`<div data-index="${i}" class="history-slider-indicator${i === 0 ? ' history-slider-indicator--active' : ''}"></div>`)
            $indicators.append($indicator);
        }
    }
    $container.trigger('tooltip:init');
};

var baseApplyTinySlider = abSlider.applyTinySlider;
abSlider.applyTinySlider = function ($container, params) {
    if ($container && $container.hasClass('js-history-slider-container')) {
        this.historySliderInit($container, params);
    } else {
        baseApplyTinySlider.call(this, $container, params);
        //give the slider a chance to load before refreshing the yotpo widget
        setTimeout(() => {
            if (window.yotpo) {
                window.yotpo.refreshWidgets();
            }
        }, 1000);
    }
};

module.exports = abSlider;
