'use strict';

function extractCompareData($elem) {
	if ($elem.length) {
		var compareItem = $elem.data('compare-info');
		return compareItem;
	}
}

function addCompareItem($elem) {
	if ($elem.length) {
		var compareItem = extractCompareData($elem);
		var url = $elem.data('add-compare-url');
		$.spinner().start();
		$.ajax({
			url: url,
			type: 'post',
			dataType: 'json',
			data: compareItem,
			success: function(data) {
				if (data.success) {
					reloadCompareToolBar();
				} else {
					handleError(data);
				}
			},
			error: function(err) {
				console.error(err);
				$.spinner().stop();
			}
		});
	}
}

function removeCompareItem($elem) {
	if ($elem.length) {
		var compareItem = extractCompareData($elem);
		var url = $('#compareToolbar').data('remove-compare-url');
		$.spinner().start();
		$.ajax({
			url: url,
			type: 'post',
			dataType: 'json',
			data: compareItem,
			success: function(data) {
				if (data.success) {
					reloadCompareToolBar();
				} else {
					handleError(data);
				}
			},
			error: function(err) {
				console.error(err);
				$.spinner().stop();
			}
		});
	}
}

function clearCompareItems($elem) {
	var url = $elem.data('href');
	$.spinner().start();
	$.ajax({
		url: url,
		type: 'post',
		dataType: 'json',
		success: function(data) {
			if (data.success) {
				reloadCompareToolBar();
			} else {
				handleError(data);
			}
		},
		error: function(err) {
			console.error('error', err);
			$.spinner().stop();
		}
	});
}

function reloadCompareToolBar() {
	var url = $('#compareToolbar').data('reload-url');
	$.ajax({
		url: url,
		type: 'get',
		success: function(data) {
			var $toolbar = $(data);
			var compareItemsHtml = $toolbar.find('.compare-items-list').html();
			var toolbarClasses = $toolbar.attr('class');
			$('#compareToolbar').attr('class', toolbarClasses).find('.compare-items-list').html(compareItemsHtml);
			updateCompareCheckboxes();
			$.spinner().stop();
		},
		error: function(err) {
			console.error(err);
			$.spinner().stop();
		}
	});
}

function updateCompareCheckboxes() {
	var $compareItems = $('#compareToolbar').find('.compare-item.active');
	var $compareCheckboxContainers = $('#product-search-results').find('.product-compare-checkbox');
	$compareCheckboxContainers.find('input').prop('checked', false);
	$compareItems.each(function() {
		var thisCompareItem = $(this).data('compare-info');
		if (thisCompareItem && thisCompareItem.id) {
			$compareCheckboxContainers.find('input[data-id="' + thisCompareItem.id + '"]').prop('checked', true);
		}
	});
}

function handleError(data) {
	displayMessage({
		success: data.success,
		msg: data.error && data.error[0] ? data.error[0] : 'And unknown error has occured, please refresh and try again.'
	});
	updateCompareCheckboxes();
}

function displayMessage(data) {
	$.spinner().stop();
	var status;
	if (data.success) {
		status = 'alert-success';
	} else {
		status = 'alert-danger';
	}

	if ($('.add-to-wishlist-messages').length === 0) {
		$('body').append(
		'<div class="add-to-wishlist-messages "></div>'
		);
	}
	$('.add-to-wishlist-messages')
		.append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

	setTimeout(function() {
		$('.add-to-wishlist-messages').remove();
	}, 5000);
}

function loadCompareModal() {
	$.spinner().start();
	var url = $('#compareModal').data('body-url');
	$.ajax({
		url: url,
		type: 'get',
		success: function(data) {
			$('#compareModal').find('.modal-body').html(data);
			$('#compareModal [data-toggle="tooltip"]').tooltip(); // reinit tooltips
			$('#compareModal').modal('show');
			$.spinner().stop();
		},
		error: function(err) {
			console.error(err);
			$.spinner().stop();
		}
	});
}

module.exports = {
	addRemoveItem: function() {
		$('#product-search-results').on('change', '.product-compare-checkbox input', function() {
			if (this.checked) {
				addCompareItem($(this).parents('.product-tile'));
			} else {
				removeCompareItem($(this).parents('.product-tile'));
			}
		});
	},
	removeItem: function() {
		$('#compareToolbar').on('click', '.compare-item.active', function(e) {
			e.preventDefault();
			removeCompareItem($(this));
		});
	},
	clearAllItems: function() {
		$('#compareToolbar').on('click', '.clear-all-tems', function(e) {
			e.preventDefault();
			clearCompareItems($(this));
		});
	},
	showCompareModal: function() {
		$('#compareToolbar').on('click', '.compare-items-button', loadCompareModal);
	},
	closeCompareModal: function() {
		$('#compareModal').on('click', '.close-modal', function() {
			$('#compareModal').modal('hide');
		});
	},
	hideCheckboxes: function() {
		// This isn't necessary anymore, but if for some reason the toolbar doesn't load,
		// we don't want this broken functionality present.
		if ($('#compareToolbar').length === 0) {
			$('.product-compare-checkbox').hide();
		}
	},
	fixCompareModalSizes: function() {
		$('#compareModal').on('shown.bs.modal', function() {
			var $modalBody = $('#compareModal').find('.modal-body');
			var $addItemPlaceholders = $modalBody.find('.add-item-placeholder');
			var imageContainerHeight = $modalBody.find('.image-container').length ? $modalBody.find('.image-container').first().outerHeight() : null;
			if (imageContainerHeight) {
				$addItemPlaceholders.css('height', imageContainerHeight + 'px');
				$addItemPlaceholders.css('line-height', imageContainerHeight + 'px');
			}
			// Set all product tiles to same height
			var maxHeight = 0;
			$modalBody.find('.product').each(function() {
				if ($(this).outerHeight() > maxHeight) {
					maxHeight = $(this).outerHeight();
				}
			}).height(maxHeight);
		});
	}
};