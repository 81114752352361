'use strict';

var updateHeader = status => {
    var $headerNav = $('.header-nav');
    var headerNavHeight = $headerNav.outerHeight();
    var $page = $('.page');

    if (status === 'fixed') {
        $headerNav.addClass('fixed');
        if (!($headerNav.closest('.transparent-header').length)) {
            $page.css('margin-top', headerNavHeight + 'px');
        }
        $headerNav.removeClass('transparent-nav');
    } else {
        $headerNav.removeClass('fixed');
        $page.css('margin-top', '');
        var isMobileOpen = $('body').hasClass('mobile-menu-in');
        if (!isMobileOpen){
            $headerNav.addClass('transparent-nav');
        }
    }
}

module.exports = function() {
    var isMobile = window.isMobile();
    if ($('header').hasClass('transparent-header') && !isMobile) {
        $('.header-nav').hover(
            function() {
                $(this).removeClass('transparent-nav');
            }, function() {
                if (window.scrollY == 0){
                    $(this).addClass('transparent-nav');
                }
            }
        );

        $(window).on('scroll', function(){
            if (window.scrollY == 0){
                $('.header-nav').addClass('transparent-nav');
            } else {
                $('.header-nav').removeClass('transparent-nav');
            }
        });
        
    }

    // Check for presence of content assets in MegaMenu
    var $megamenuDropdown = $('.megamenu > .dropdown-menu');

    $megamenuDropdown.each((index, element) => {
        var $megamenu = $(element);
        if ($megamenu.find('.megamenu-asset-1').children().length) {
            $megamenu.addClass('has-asset-1');
        }
        if ($megamenu.find('.megamenu-asset-2').children().length) {
            $megamenu.addClass('has-asset-2');
        }
    });

    // Show/hide content assets on mobile based on category level
    if (isMobile) {
        var $thirdMenuBackBtn = $('.megamenu .menu-subcategories > .dropdown-item'),
            $thirdSubLink = $('.megamenu .menu-subcategories > .dropdown-item > .dropdown-link');
            
        $thirdSubLink.on('click', function () {
            $('.megamenu.show .menu-subcategories').addClass('thirdCatOpen');
        });
        $thirdMenuBackBtn.on('click', function () {
            $('.megamenu.show .menu-subcategories').removeClass('thirdCatOpen');
        });
    }

    $('.main-menu .navbar-nav').on('mouseenter mouseleave', function () {
        $(this).toggleClass('nav-item-hover');
    });

    $('header .site-search').on('mouseenter', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        $siteSearch.addClass('expanded');
    });

    $('header .site-search').on('mouseleave', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        var $input = $siteSearch.find('input');
        // Only collapse if the user isn't typing and hasn't entered text
        if (!$input.is(':focus') && !$input.val()) {
            $siteSearch.removeClass('expanded');
        }
    });

    $('#headerSearchModal').on('shown.bs.modal', () => {
        $('.search-field').trigger('focus');
    })
    .on('show.bs.modal', () => {
         // toggle header-search-modal for .modal-backdrop when modal is open
         $('.modal-backdrop').addClass('header-search-modal');
    })
    .on('hidden.bs.modal', () => {
        // toggle header-search-modal for .modal-backdrop when modal is closed
        $('.modal-backdrop').removeClass('header-search-modal');
    });

    $('form[name=simpleSearch]').on('submit', event => {
        var $form = $(event.target);
        var $input = $('input[name=q]',$form);
        //keep search from submitting if query is empty
        if ($input.val() == "") {
            $input.focus();
            event.preventDefault();
        }
    });

    // Fixes a mobile menu issue where the parent menu becomes scrollable while still viewing the child menu
    var $legacyMegamenu = $('.legacy-megamenu');
    if (isMobile && $legacyMegamenu.length > 0) {
        var $legacyParent = $legacyMegamenu.parent();

        $legacyMegamenu.find('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]')
            .on('click', function () {
                $legacyParent.addClass('overflow-hidden');
            });

        $legacyMegamenu
            .on('click', '.back', function () {
                $legacyParent.removeClass('overflow-hidden');
            });
    }
};
