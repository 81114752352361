'use strict';

const base = require('../product/base');
const focusHelper = require('base/components/focus');
var wishlistBase = require('core/wishlist/wishlist');
var modal = require('../components/modal');

const editWishlistProductModalSelector = '#editWishlistProductModal';

/**
 * handles update button on click for edit wishlist modal from wishlist-show and others
 */
 function updateWishlistItem() {
    $('body').on('click', '.btn-update-wishlist-product', function (e) {
        e.preventDefault();

        var updateButtonBlock = $(this).closest('.wishlist-item-update-button-block').find('.update-wishlist-url');
        var updateProductUrl = updateButtonBlock.val();
        var uuid = updateButtonBlock.data('uuid');

        var form = {
            uuid: uuid,
            pid: base.methods.getPidValue($(this))
        };

        $(editWishlistProductModalSelector).spinner().start();

        $.ajax({
            url: updateProductUrl,
            type: 'post',
            context: this,
            data: form,
            dataType: 'json',
            success: function () {
                $.spinner().start();
                $(editWishlistProductModalSelector).spinner().stop();
                $(editWishlistProductModalSelector).remove();
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');
                var pageNumber = $('.wishlistItemCardsData').data('page-number') - 1;
                renderNewPageOfItems(pageNumber, false, '.product-info .edit-add-to-wishlist .edit:first');
            },
            error: function () {
                var msg = $('.btn-update-wishlist-product').data('error-msg');

                $(editWishlistProductModalSelector).spinner().stop();
                $(editWishlistProductModalSelector).remove();
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');

                if ($('.update-wishlist-messages').length === 0) {
                    $('body').append(
                        '<div class="update-wishlist-messages "></div>'
                    );
                }

                $('.update-wishlist-messages')
                    .append('<div class="update-wishlist-alert text-center alert-danger">' + msg + '</div>');

                setTimeout(function () {
                    if (window.yotpo) {
                        window.yotpo.refreshWidgets();
                      }

                    $('.update-wishlist-messages').remove();
                }, 5000);
            }
        });
    });
}

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.wishlistItemCardsData').data('public-view');
    var listUUID = $('.wishlistItemCardsData').data('uuid');
    var url = $('.wishlistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done(function (data) {
        $('.wishlistItemCards').empty();
        $('body .wishlistItemCards').parent().html(data);

        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }

        base.enableQuantitySteppers();
    }).fail(function () {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

/**
 * handles tabbing on wishlist modal
 */
 function trapEditWishlistProductModalFocus() {
    $('body').on('keydown', editWishlistProductModalSelector, function (e) {
        var focusParams = {
            event: e,
            containerSelector: editWishlistProductModalSelector,
            firstElementSelector: '.close',
            lastElementSelector: '.btn-update-wishlist-product',
            nextToLastElementSelector: '.select-size'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
}


/**
 * update window wishlist data
 * @param {string} data - data returned from the server's ajax call
 */
 wishlistBase.updateWishlistUncachedData = function(data) {
    if (data.success) {
        var wishlistPIDs = new Set(window.UncachedData.wishlistPIDs);
        var wishlistItemArray = window.UncachedData.wishlistPIDs;
        if (data.add) {
            // wishlistPIDs.add(data.wishlistpid);
            wishlistPIDs.add(data.itemObject);
            if (window.yotpo) {
                window.yotpo.refreshWidgets();
            }
        } else {
            // wishlistPIDs.delete(data.wishlistpid);
            if (data.listIsEmpty) {
                // just delete it all
                wishlistPIDs = new Set();
            } else {
                wishlistPIDs.forEach((item) => {
                    // check if color exists
                    if (data.itemObject.color == null) {
                        // then just compare productID and ensure that item.color is also
                        if (item.color == null && item.productID == data.itemObject.productID && item.masterID == data.itemObject.masterID) {
                            wishlistPIDs.delete(item);
                        }
                    } else {
                        // this will address the variants
                        if (item.color) {
                            if (item.masterID == data.itemObject.masterID && item.color.value == data.itemObject.color.value) {
                                wishlistPIDs.delete(item);
                            }
                        }

                        // this will address masters
                        if (item.masterID == item.productID && item.masterID == data.itemObject.masterID && item.color == null) {
                            wishlistPIDs.delete(item);
                        }
                    }
                });
            }
            if (window.yotpo) {
                window.yotpo.refreshWidgets();
            }
            // wishlistPIDs.delete(data.itemObject);
        }

        //TODO RVW Array.from is ES6 (not IE11 supported, does this polyfill with webpack?)
        window.UncachedData.wishlistPIDs = Array.from(wishlistPIDs);
    }
}

/**
 * update clicked links attributes and text
 * @param {Object} specificLinks - jQuery object representing selection of wishlist links
 */
 wishlistBase.updateWishlistLinkData = function(specificLinks, swatchColorValue) {
    if (!window || !window.UncachedData || !window.UncachedData.wishlistPIDs) {
        return;
    }

    var wishlistLinks = specificLinks || $('a.wishlist');
    // var swatchColor = swatchColorValue || null;
    if (!wishlistLinks || !wishlistLinks.length) {
        return;
    }

    var wishlistPIDs = new Set(window.UncachedData.wishlistPIDs);
    var wishlistItemArray = window.UncachedData.wishlistPIDs;

    wishlistLinks.each((_i, wishlistLink) => {
        var $wishlistLink = $(wishlistLink);
        var wishlistpid = $wishlistLink.attr('data-wishlistpid');
        var currentSwatchColorValue = null;
        var colorInWishlist = null;
        if (wishlistpid) {
            var isInWishlist = isProductID(wishlistItemArray, wishlistpid); // wishlistPIDs.has(wishlistpid);

            // check via color way of the selectedSwatch
            if (!isInWishlist) {
                // see if we can grab it
                var $selectedSwatch = $wishlistLink.parents(".product-tile").find(".swatches .swatch.selected");
                if ($selectedSwatch && $selectedSwatch.length > 0) {
                    currentSwatchColorValue = $selectedSwatch.attr('data-color-value');
                    isInWishlist = isColorwayInWishlist(wishlistpid, currentSwatchColorValue);
                    // swatchColorValue = isInWishlist ? currentSwatchColorValue : swatchColorValue
                }
            } else {
                // because a master products default colorway could also have variants as well defined we need to add the color way
                var $selectedSwatch = $wishlistLink.parents(".product-tile").find(".swatches .swatch.selected");
                if ($selectedSwatch && $selectedSwatch.length > 0) {
                    currentSwatchColorValue = swatchColorValue ? swatchColorValue : $selectedSwatch.attr('data-color-value');
                    colorInWishlist = isColorwayInWishlist(wishlistpid, currentSwatchColorValue);
                    // swatchColorValue = isInWishlist ? currentSwatchColorValue : swatchColorValue
                } else {
                    currentSwatchColorValue = swatchColorValue;
                }
            }
            if (!isInWishlist && swatchColorValue) {
                isInWishlist = isColorwayInWishlist(wishlistpid, swatchColorValue);
                currentSwatchColorValue = isInWishlist ? swatchColorValue : currentSwatchColorValue;
            }

            $wishlistLink.attr('data-inwishlist', isInWishlist);
            if (isInWishlist) {
                // add the colorway value
                if (currentSwatchColorValue) {
                    $wishlistLink.attr('data-color-value', currentSwatchColorValue);
                }
                $wishlistLink.attr('title', $wishlistLink.attr('data-removemessage'));
                if ($wishlistLink.data('includetext') === true) {
                    $wishlistLink.find('span').html($wishlistLink.attr('data-removemessage'));
                }
            } else {
                // if there is the attribute for color value remove it
                if ($wishlistLink.attr('data-color-value')) {
                    $wishlistLink.removeAttr('data-color-value');
                }

                $wishlistLink.attr('title', $wishlistLink.attr('data-addmessage'));
                if ($wishlistLink.data('includeicon') === true) {
                    $wishlistLink.find('span').html($wishlistLink.attr('data-addmessage'));
                }
            }

            var icon = $wishlistLink.find('.wishlist-icon');
            if (icon && icon.length) {
                $(icon).toggleClass('selected', isInWishlist);
            }
        }
    });
}


/**
 * attach onclick for wishlist icon toggle functionality
 */
 wishlistBase.toggleWishlist = function() {
    $('body').on('click', 'a.wishlist', function (e) {
        var clicked = $(this);
        e.preventDefault();
        var url = clicked.attr('href');
        var wishlistpid = clicked.attr('data-wishlistpid');
        var colorValue = clicked.attr('data-color-value');
        console.log('WishlistPID:'+ wishlistpid + " | ColorValue: "+ colorValue);
        //TODO RVW option products
        // var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
        // var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
        // optionId = optionId || null;
        // optionVal = optionVal || null;
        if (!url || !wishlistpid) {
            return;
        }

        var $this = $(this);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                wishlistpid: wishlistpid,
                colorValue: colorValue
                // optionId: optionId,
                // optionVal: optionVal
            },
            success: function (data) {
                // console.log(data);
                wishlistBase.displayMessage(data);
                wishlistBase.updateWishlistUncachedData(data);
                wishlistBase.updateWishlistLinkData();
                wishlistBase.methods.updateWishlistCartStatus(data, $this);
            },
            error: function (err) {
                wishlistBase.displayMessage(err);
            }
        });
    });
}


/**
 * isColorwayInWishlist - helper utility function for colorway / variant
 * @param {*} wishlistLinkPID
 * @param {*} swatchColorValue
 */
 function isColorwayInWishlist(wishlistLinkPID, swatchColorValue) {
    var wishlistItemArray = window.UncachedData.wishlistPIDs;

    return wishlistItemArray.some(function(wishlistItem) {
        return wishlistItem.masterID === wishlistLinkPID && wishlistItem.color && wishlistItem.color.value === swatchColorValue;
    });
}

function isProductID(wishlistArray, productID) {
    return wishlistArray.some(function(wishlistItem) {
        return productID === wishlistItem.productID;
      });
}

/**
 * attach on click on wishlist-show edit button to launch modal
 */
wishlistBase.viewProductViaEdit = function () {
    $('body').on('click', '.edit-add-to-wishlist .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        var fullProductUrl = $(this).parent().parent().find('.pdp-link .link').attr('href');
        $(e.target).trigger('editwishlistproduct:show');
        modal.getModalHtmlElement('editWishlistProductModal', 'quick-view-dialog');
        wishlistBase.fillModalElement(editProductUrl, fullProductUrl);
    });
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
//TODO RVW unified in some way with quickView.js fillModalElement function?
wishlistBase.fillModalElement =  function (editProductUrl, fullProductUrl) {
    var $modal = $(editWishlistProductModalSelector);
    $modal.find('.modal-body').spinner().start();
    if (editProductUrl) {
        $.ajax({
            url: editProductUrl,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                var parsedHtml = modal.parseHtml(data.renderedTemplate);
    
                $modal.find('.modal-body').empty();
                $modal.find('.modal-body').html(parsedHtml.body);
                $modal.find('.modal-footer').html(parsedHtml.footer);
                $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
                $modal.find('.enter-message').text(data.enterDialogMessage);
                $modal.find('.full-pdp-link').attr('href', fullProductUrl );
                $modal.find('.full-pdp-link').text('View Full Details');
                $modal.find(editWishlistProductModalSelector).modal('show');
    
                $('body').trigger('editwishlistproduct:ready', $(editWishlistProductModalSelector));
    
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * attaches on click for clicking the "x" from wishlist show or account show
 */
wishlistBase.removeFromWishlist = function() {
    $('body').on('click', '.remove-from-wishlist', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var elMyAccount = $('.account-page').length;

        // If user is in my account page, call Wishlist-RemoveProductAccount() end point and replace html with response
        if (elMyAccount > 0) {
            $('.wishlist-account-card').spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                data: {},
                success: function (html) {
                    $('.wishlist-account-card>.order-card').remove();
                    $('.wishlist-account-card').append(html);
                    $('.wishlist-account-card').spinner().stop();
                },
                error: function () {
                    var $elToAppend = $('.wishlist-account-card');
                    $elToAppend.spinner().stop();
                    var msg = $elToAppend.data('error-msg');
                    wishlistBase.displayMessage($elToAppend, msg);
                }
            });
        // else user is in wishlist landing page, call Wishlist-RemoveProduct() end point and re-render container page
        } else {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function () {
                    var pageNumber = $('.wishlistItemCardsData').data('page-number') - 1;
                    renderNewPageOfItems(pageNumber, false);
                },
                error: function () {
                    $.spinner().stop();
                    var $elToAppendWL = $('.wishlistItemCards');
                    var msg = $elToAppendWL.data('error-msg');
                    wishlistBase.displayMessage($elToAppendWL, msg);
                }
            });
        }
    });
}

module.exports = {
    updateWishlistItem: updateWishlistItem,
    trapEditWishlistProductModalFocus: trapEditWishlistProductModalFocus,
    renderNewPageOfItems: renderNewPageOfItems,
    init: function () {
        wishlistBase.cartUpdate();
        wishlistBase.toggleWishlist();
        wishlistBase.updateWishlistLinkData();
        wishlistBase.copyWishlistLink();
        wishlistBase.updateQuickView();
        wishlistBase.viewProductViaEdit();
        wishlistBase.focusEditWishlistProductModal();
        wishlistBase.onClosingEditWishlistProductModal();
        wishlistBase.trapEditWishlistProductModalFocus();
        module.exports.updateWishlistItem();
        wishlistBase.addToCartFromWishlist();
        wishlistBase.toggleWishlistStatus();
        wishlistBase.toggleWishlistItemStatus();
        module.exports.removeFromWishlist();
        wishlistBase.moreWLItems();
        wishlistBase.fillModalElement();
    }
};

Object.keys(wishlistBase).forEach(function (prop) {
    // eslint-disable-next-line no-prototype-builtins
    if (!module.exports.hasOwnProperty(prop)) {
        module.exports[prop] = wishlistBase[prop];
    }
});
