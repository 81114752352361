module.exports = {
    cardNumberJson: {
        "visa": "^4[0-9]{12}(?:[0-9]{3})?$",
        "mastercard": "^5[1-5][0-9]{14}$",
        "master": "^5[1-5][0-9]{14}$",
        "mc": "^5[1-5][0-9]{14}$",
        "american express": "^3[47][0-9]{13}$",
        "amex": "^3[47][0-9]{13}$",
        "diners club": "^3(?:0[0-5]|[68][0-9])[0-9]{11}$",
        "dinersclub": "^3(?:0[0-5]|[68][0-9])[0-9]{11}$",
        "diners": "^3(?:0[0-5]|[68][0-9])[0-9]{11}$",
        "discover": "^6(?:011|5[0-9]{2})[0-9]{12}$",
        "jcb": "^(?:2131|1800|35\d{3})\d{11}$",
        "maestro": "^(5018|5020|503[3-8]|5868|5893|6304|6759|676[1-3])[0-9]{12}$"
    },


    // iterate through the cardNumberJson and check if the card number matches any of the regex
    getCardType: function(cardNumber) {
        var cardType = null;
        var cardNumber = cardNumber.replace(/\s/g, '');
        for (var key in this.cardNumberJson) {
            if (new RegExp(this.cardNumberJson[key]).test(cardNumber)) {
                cardType = key;
                break;
            }
        }
        return cardType;
    }
}
