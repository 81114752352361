'use strict';

function toggleRequiredCompanyField() {
    var $input = $('#registration-form-companyname');
    var $formGroup = $input.parents('.form-group');
    if ($input.attr('required')) {
        $input.prop('required', false);
    } else {
        $input.prop('required', true);
    }
    $formGroup.toggleClass('required');
}

var login = {
    handleEvents: function () {
        // Open/close b2b fields on login/register page
        $('.registration .show-b2b-fields').on('change', function () {
            $('.b2b-fields').slideToggle();
            toggleRequiredCompanyField();
        });
    }
};

module.exports = login;