'use strict';

module.exports = {
    init: function() {
        var $exitSurveyScript = $('#exitSurvey');
        var $engagementSurveyScript= $('#engagementSurvey');

        if ($exitSurveyScript.length > 0) {
            // Wait for user to mouse out of viewport window, then display exit survey
            var showExitSurvey = function () {
                var event = new CustomEvent("showExitSurvey");
                document.dispatchEvent(event);
                document.removeEventListener('mouseleave', showExitSurvey);
            };
            document.addEventListener('mouseleave', showExitSurvey);
        }

        if ($engagementSurveyScript.length > 0) {
            var delay = $engagementSurveyScript.data('delay') || 1;

            // Automatically display engagement survey after X seconds
            setTimeout(() => {
                var event = new CustomEvent("showEngagementSurvey");
                document.dispatchEvent(event);
            }, delay * 1000);
        }
    }
};
