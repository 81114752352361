'use strict';

module.exports = {
	emailSignupModalPop: function() {
        var $emailSignupModal = $('.emailSignupModalSettings');
        if ($emailSignupModal.length === 0) {
            return;
        }

        var actionUrl = $emailSignupModal.data('action-url');
        $.ajax({
            type: 'GET',
            url: actionUrl,
            success: function(response) {
                if (response.success) {
                    var emailSignupModalSettings = response.emailSignupModal;
                    if (!!emailSignupModalSettings) {
                        if (emailSignupModalSettings.emailAutoPopupEnabled && emailSignupModalSettings.thisPageIsEligible) {
                            $('#mailingListDetailsModal').on('show.bs.modal', function () {
                                return $.ajax({
                                    type: 'POST',
                                    url: emailSignupModalSettings.openAutoModalUrl,
                                    error: function(error) {
                                        window.console.error('Email Signup Auto Modal Notification Failed', error);
                                    }
                                });
                            })

                            var timeDelay = emailSignupModalSettings.emailAutoPopupDelaySeconds * 1000;
                            $('#mailingListDetailsModal .modal-body').addClass('d-none');
                            $('#mailingListDetailsModal .modal-body.signup-first-step').removeClass('d-none');
                            $('#mailingListDetailsModal .modal-title').addClass('d-none');
                            $('#mailingListDetailsModal .modal-title.signup-first-step').removeClass('d-none');
                            setTimeout(function() {
                                $('#mailingListDetailsModal').modal();
                            }, timeDelay);

                            // if feature is on, reset form if modal closed without completion, to avoid conflict with footer email signup process
                            $('#mailingListDetailsModal').on('hidden.bs.modal', function() {
                                $('#mailingListDetailsModal .modal-body').addClass('d-none');
                                $('#mailingListDetailsModal .modal-body.signup-details-form').removeClass('d-none');
                                $('#mailingListDetailsModal .modal-title').addClass('d-none');
                                $('#mailingListDetailsModal .modal-title.signup-details').removeClass('d-none');
                            });
                        }

                        if (emailSignupModalSettings.emailButtonEnabled && emailSignupModalSettings.thisPageIsEligibleForButton) {
                            $('.email-signup-button').data('closeButtonUrl', emailSignupModalSettings.closeButtonUrl);
                            $('.email-signup-button').show();
                        }
                    }
                }
            },
            error: function(error) {
                window.console.error('Email Signup Modal Failed', error);
            }
        });
    },
    emailModalButton: function() {
        var closeButton = function() {
            $('.email-signup-button').hide();
            var url = $('.email-signup-button').data('closeButtonUrl');
            if (url) {
                return $.ajax({
                    type: 'POST',
                    url: url,
                    error: function(error) {
                        window.console.error('Email Signup Close Failed', error);
                    }
                });
            }
        };

        $('.email-signup-button-content, .email-signup-button-arrow').click(function() {
            $('#mailingListDetailsModal .modal-body').addClass('d-none');
            $('#mailingListDetailsModal .modal-body.signup-first-step').removeClass('d-none');
            $('#mailingListDetailsModal .modal-title').addClass('d-none');
            $('#mailingListDetailsModal .modal-title.signup-first-step').removeClass('d-none');
            $('#mailingListDetailsModal').modal();
        });
        $('.email-signup-button-close').click(function() {
            closeButton();
        });
        $('#mailingListDetailsModal').on('hide.bs.modal', function () {
            var isModalStep2 = $('#mailingListDetailsModal .modal-body.signup-first-step').hasClass('d-none');
            if (isModalStep2) {
                closeButton();
            }
        });
	}
};
