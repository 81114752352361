'use strict';

module.exports = {
	taxConfirmationModal: function() {
		$('body').on('checkout:taxConfirmationModal', function (e, data) {
            // we only show the modle if multiple geocodes are found
            if (data && data.order && data.order.mojoTaxInfo && data.order.mojoTaxInfo.geocode && data.order.mojoTaxInfo.geocode.multipleFound) {
                var $taxConfirmationModal = $('#taxConfirmationModal');
                var $optionContainer = $taxConfirmationModal.find('.geocode-options-container');
                var $cityZipMessage = $taxConfirmationModal.find('.geocode-city-zip-message');
                var $continueButton = $taxConfirmationModal.find('.geocode-continue-button');

                var goBackToEdit = true;
                var geocodes = data.order.mojoTaxInfo.multipleGeocodes;
                var updateGeocodeAction = data.order.mojoTaxInfo.updateGeocodeAction;

                // Set the continue button to disabled
                $continueButton.prop('disabled', true);

                // Clear the existing options in case multiple displays
                $optionContainer.html('');

                // Load the options into the container
                geocodes.forEach(function(geocode) {
                    $optionContainer.append('<label class="d-block'+ (geocode.closestMatch ? ' mb-3 pb-3 border-bottom' : '') +'"><input type="radio" name="geocode" value="' + geocode.value + '" class="card-input-element d-none" id="geocode' + geocode.value + '"><div class="card bg-light my-2">' + (geocode.county !== null ? geocode.county : geocode.city) + (geocode.closestMatch ? '<div class="position-absolute" style="right:20px">*</div>' : '') + '</div></label>');
                });

                // Add message displaying the city + zip the user entered
                $cityZipMessage.html('We found multiple counties associated with <strong>' + data.order.mojoTaxInfo.city + '</strong>, <strong>' + data.order.mojoTaxInfo.postalCode + '</strong>');

                // Add event listeners to the form
                $taxConfirmationModal.off().on('hide.bs.modal', function (e) {
                    if (goBackToEdit === true) {
                        $('.customer-summary .edit-button').trigger('click'); // Might be a better way to trigger this
                    }
                })

                // Add event listeners to edit shipping button
                $('.edit-shipping-button').off().on('click', function() {
                    $taxConfirmationModal.modal('hide');
                });

                $optionContainer.find('[name="geocode"]').on('change', function() {
                    $continueButton.prop('disabled', false);
                });
                $continueButton.off().on('click', function() {
                    $.spinner().start();

                    var selectedGeocode = $optionContainer.find('[name="geocode"]:checked').val();
                    $.ajax({
                        type: 'POST',
                        async: false,
                        url: updateGeocodeAction,
                        data: { geocode: selectedGeocode},
                        success: function success(data) {
                            // Update checkout data
                            $('body').trigger('checkout:updateCheckoutView', data);
                            // Set to false so we don't send them back to shipping section
                            goBackToEdit = false;
                            // Stop loading
                            $.spinner().stop();
                            // Hide the modal
                            $taxConfirmationModal.modal('hide');
                        }
                    });
                });

                // After everything is ready, show the modal
                $taxConfirmationModal.modal('show');
            }
        });

        // Get Mojo Tax Data on page load from data attribute
        // Triggering the modal to display on page load resolves a bug on the old site
        // where reloading the page when the modal was open would allow you to proceed through checkout.
        var mojoTaxInfo = $('#taxConfirmationModal').data('mojo-tax-info');
        if (mojoTaxInfo && mojoTaxInfo.geocode && mojoTaxInfo.geocode.multipleFound) {
            $('body').trigger('checkout:taxConfirmationModal', {
                order: {
                    mojoTaxInfo: mojoTaxInfo
                }
            });
        }
	},
    updateCheckoutView: function() {
        $('body').on('checkout:updateCheckoutView', function (e, data) {
            console.log('checkout:updateCheckoutView', data);
            $('body').trigger('checkout:taxConfirmationModal', data);
        });
    }
};
