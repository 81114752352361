'use strict';

const { updateAddToCart } = require('base/product/detail');

module.exports = {
    updateAttributes: function() {
        $('body').on('product:statusUpdate', function (e, data) {
            var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

            $productContainer.find('.main-content-group .product-attributes')
                .empty()
                .html(data.attributesHtml);

            $('body').trigger('tooltip:init');
            updateAddToCart();
        });
    },
    updateAddAllToCart: function () {
        // if default variants are selected, enable the add all to cart btn on load
        var enable = $('.product-availability').toArray().every(function (item) {
            return $(item).data('available') && $(item).data('ready-to-order');
        });
        $('button.add-to-cart-global').attr('disabled', !enable);
    },
    copyProductLink: function () {
        $('body').on('click', '#fa-link', function () {
            event.preventDefault();
            var $temp = $('<input>');
            $('body').append($temp);
            $temp.val($('#shareUrl').val()).select();
            document.execCommand('copy');
            $temp.remove();
            $('.copy-link-message').attr('role', 'alert');
            $('.copy-link-message').removeClass('d-none');
            setTimeout(function () {
                $('.copy-link-message').addClass('d-none');
            }, 3000);
        });
    },
    showSpinner: function() {
        $('body').on('product:beforeAddToCart, product:beforeAttributeSelect', function () {
            $.spinner().start();
        });
    },
    setCollapsibleContentState: function() {
        var $collapsibleContentGroup = $('.collapsible-content-group');

        $collapsibleContentGroup.each(function () {
            var expandCollapsibleContentGroup = $(this).data('expand');
            if (expandCollapsibleContentGroup == true) {
                $(this).addClass('active');
            }
        });
    }
};
