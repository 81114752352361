'use strict';

const base = require('client_core/product/base');
const waterfall = require('redkap_core/components/waterfall');


/**
 * Changed from Autobahn base to update the selected option (cosmetic bug only). When one option is
 * available and the customer deselects it in a select dropdown, the server doesn't deselect the attribute,
 * which causes the Add to Cart button to be enabled without all attributes being visibly selected.
 *
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */


function enhancedAddToCartMessaging() {
    var $addedToCartModal = $('#addedToCartModal');

    $addedToCartModal.on('hidden.bs.modal', function (e) {
        $('.fixed-header').removeClass('z-index-over-modal-background');
    });

    $('body').on('product:afterAddToCart', function (e, data) {
        if (data && data.error === false) {
            var addedUUID = data.pliUUID;
            var items = data.cart && data.cart.items;
            var viewCartButton = data.cart.viewCartText;

            var addedProduct = null;

            if (items) {
                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    if (item.UUID === addedUUID) {
                        addedProduct = item;
                        break;
                    }
                }

                if (addedProduct) {
                    var modalBodyHtml = '';

                    var isCustomizerProduct = addedProduct.hasOwnProperty('customizer') ? addedProduct.customizer.isCustomized : false;
                    // Create the product image
                    var imageUrl = isCustomizerProduct ? addedProduct.customizer.recipePreview : addedProduct.images.small[0].url;
                    var imageAlt = addedProduct.images.small[0].alt;
                    var productImageContainer = '<div class="pr-3 image-col"><div class="item-image-container"><img class="item-image" src="' + imageUrl + '" alt="' + imageAlt + '" /></div></div>';

                    // Create the right side details of the card
                    var productName = '<div class="item-name">' + (addedProduct.shortName && data.showShortName ? addedProduct.shortName : addedProduct.productName) + (isCustomizerProduct? ' ' + data.customizerAppendLabel : '') + '</div>';
                    var productPrice = '<div class="attribute-pair price"><span class="attribute-value item-total"><span class="pricing line-item-total-price-amount">' + addedProduct.price.sales.formatted + '</span></span></div>';
                    var availabilityMessage = '<div class="attribute-pair availability-message">' + addedProduct.availability.messages[0] + '</div>';

                    var attributeHtml = '';
                    addedProduct.variationAttributes.forEach(function(attribute) {
                        attributeHtml += '<div class="attribute-pair" data-attr-id="' + attribute.attributeId + '">';
                        attributeHtml += '<span class="attribute-key">' + attribute.displayName + ':</span>';
                        attributeHtml += '<span class="attribute-value">' + attribute.displayValue + '</span>';
                        attributeHtml += '</div>';
                    });
                    attributeHtml = '<div class="pb-1 item-attributes">' + attributeHtml + '</div>';

                    var productDetailsContainer = '<div class="col">' + productName + productPrice + attributeHtml + availabilityMessage + '</div>';

                    // Create the modal body
                    modalBodyHtml = '<div class="minicart"><div class="row ml-0 line-item">' + productImageContainer + productDetailsContainer + '</div></div>';

                    // Set the position of the modal to the height of the header
                    var headerHeight = $('#top-header').outerHeight();
                    $addedToCartModal.find('.modal-dialog').css('top', (headerHeight + 24) + 'px');

                    // Add the modal body that we generated above
                    $addedToCartModal.find('.modal-body').html(modalBodyHtml);

                    // Update view cart button with number of items
                    $addedToCartModal.find('.view-cart-button').text(viewCartButton);

                    // Update styles for footer container and left column button
                    $addedToCartModal.find('.added-to-cart-footer-row').addClass('flex-nowrap');
                    $addedToCartModal.find('.added-to-cart-footer-row .left-column').removeClass('col-6').addClass('col');

                    // Add class so the header displays over the background
                    $('.fixed-header').addClass('z-index-over-modal-background');

                    // Now, remove the old messaging
                    $('.add-to-cart-messages').remove();

                    // Finally show the modal
                    $addedToCartModal.modal('show');

                    // Set a time out to hide the modal after 5 seconds
                    setTimeout(function () {
                        $addedToCartModal.modal('hide');
                    }, 5000);
                } else {
                    console.error('Cart Message: Unable to find recently added product:', data);
                }
            } else {
                console.error('Cart Message: Missing "items".', data);
            }
        } else {
            console.error('Cart Message: Missing "data" or Error returned.', data);
        }
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImages = response.product.images;
    var $oldWishlistIcon = $productContainer.find('div.slide a.wishlist');
    this.createSlider(primaryImages, $productContainer.find('.primary-images'));
    this.updateModelText(response.product, $productContainer.find('.primary-images .row'));

    // Update for Waterfall
    var $waterfallContainer = $('.waterfall-container');
    if ($waterfallContainer.length > 0) {
        var dImages = $waterfallContainer.find('div.slide > img.waterfall-target');
        var isInModal = $waterfallContainer.parents('.modal').length > 0;
        for (let imageIdx = 0; imageIdx < dImages.length; imageIdx++) {
            let dImg = dImages[imageIdx];
            if (primaryImages.large.length > imageIdx && dImg) {
                let primaryImg = primaryImages.large[imageIdx];
                let newUrl = primaryImg.url;

                // Don't show video thumbnails in modals
                if (primaryImg.isVideo && isInModal) {
                    continue;
                }

                dImg.src = newUrl;
                if (primaryImages['hi-res'].length > imageIdx) {
                    let newZoomUrl = primaryImages['hi-res'][imageIdx].url;
                    $(dImg).parent().data('zoom-image', newZoomUrl);
                }
                // Update video play thumbnail
                var $slideImg = $(dImg);
                var $slide = $slideImg.parent();
                if (primaryImg.isVideo) {
                    $slide.append('<div class="video-thumbnail-play text-center"><div class="icon-play"></div></div>');
                    $slide.attr('data-video-url', primaryImg.videoURL);
                    $slideImg.addClass('video-thumbnail').removeClass('product-img btn')
                } else {
                    $slide.attr('data-video-url', '');
                    $slideImg.removeClass('video-thumbnail').addClass('product-img btn').siblings('.video-thumbnail-play').remove();
                }
            }
        }
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices:not(.const-prices) .price', $productContainer).length
            ? $('.prices:not(.const-prices) .price', $productContainer)
            : $('.prices:not(.const-prices) .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    this.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $($oldWishlistIcon[0]);
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);

        //Make heart icon accurate
        var wishlist = require('core/wishlist/wishlist');
        wishlist.updateWishlistLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('div.primary-images-main div.slide img');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.after($newWishlistIcon.clone(true));
        });
    }

    // Initialize tooltips
    $('body').trigger('tooltip:init');
}

base.enhancedAddToCartMessaging = enhancedAddToCartMessaging;
base.methods.handleVariantResponse = handleVariantResponse;

module.exports = base;
